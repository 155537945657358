/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';

import { DataTable } from '@/components/ui/datatable/data-table.tsx';
import { Button } from '@/components/ui/button';
import { readManyV2WmsPackageTypesGet, refreshV2WmsPackageTypesRefreshPost, setNameV2WmsPackageTypesIdPut, updateV2WmsRulesPackageTypePut, readManyV2WmsInvoiceCustomersGet, readManyV2WmsRulesPackageTypeGet } from '@/client/services.gen';
import type { PackageType } from '@/client/types.gen.ts';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form, FormField, FormItem, FormControl, FormMessage, FormLabel } from '@/components/ui/form';
import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Input } from '@/components/ui/input';
import { PencilIcon } from '@heroicons/react/24/outline';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

const packageSchema = z.object({
    name: z.string().min(1, 'Name is required'),
});

const priceSchema = z.object({
    entries: z.array(z.object({
        customerIds: z.array(z.string()).min(1, 'At least one customer is required'),
        price: z.string().min(1, 'Price is required'),
        isExisting: z.boolean().optional()
    })).min(1, 'At least one entry is required'),
});

const PriceCollapsibleContent: React.FC<{ row: any, onEditPrice: (id: string) => void }> = ({ row, onEditPrice }) => {
    if (!row.prices?.length) {
        return (
            <div className="text-sm text-gray-500">
                No prices set for this package.
                <Button 
                    variant="ghost" 
                    size="sm" 
                    onClick={() => onEditPrice(row.id)}
                >
                    Set Price
                </Button>
            </div>
        );
    }

    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <h3 className="text-lg font-medium">Price Details</h3>
                <Button 
                    variant="ghost" 
                    size="sm" 
                    onClick={() => onEditPrice(row.id)}
                >
                    Edit Prices
                </Button>
            </div>
            <DataTable
                loading={false}
                data={row.prices}
                columns={[
                    {
                        accessorKey: 'customer_name',
                        header: "Customer",
                    },
                    {
                        accessorKey: 'price',
                        header: "Price",
                        cell: ({ row }) => `$${Number(row.original.price).toFixed(2)}`
                    }
                ]}
                showPagination={false}
                showToolBar={false}
            />
        </div>
    );
};

const Packages = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState<PackageType[]>([]);
    const [selectedPackage, setSelectedPackage] = useState<PackageType | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isPriceDialogOpen, setIsPriceDialogOpen] = useState(false);
    const [customers, setCustomers] = useState<any[]>([]);

    useEffect(() => {
        const fetchCustomers = async () => {
            const { data } = await readManyV2WmsInvoiceCustomersGet();
            setCustomers(data || []);
        };
        fetchCustomers();
    }, []);

    const fetchPackages = async () => {
        setIsLoading(true);
        try {
            const [packagesResponse, pricesResponse] = await Promise.all([
                readManyV2WmsPackageTypesGet(),
                readManyV2WmsRulesPackageTypeGet(),
            ]);

            // Create a map of customer IDs to customer names
            const customerMap = customers.reduce((acc: { [key: string]: string }, customer: any) => {
                acc[customer.id] = customer.name;
                return acc;
            }, {});

            // Create a map of package_type_id to prices with customer names
            const pricesByPackage = (pricesResponse.data || []).reduce((acc: { [key: string]: any[] }, price: any) => {
                const packageId = price.package_type_id;
                if (!acc[packageId]) {
                    acc[packageId] = [];
                }
                acc[packageId].push({
                    ...price,
                    customer_name: customerMap[price.invoice_customer_id] || 'Unknown Customer'
                });
                return acc;
            }, {});

            const joinedData = (packagesResponse.data || []).map(pkg => ({
                ...pkg,
                prices: pricesByPackage[pkg.id] || []
            }));

            setTableData(joinedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPackages();
    }, []);

    const handleEditPackage = (pkg: PackageType) => {
        setSelectedPackage(pkg);
        setIsDialogOpen(true);
    };

    const handleRefresh = async () => {
        setIsLoading(true);
        const { error } = await refreshV2WmsPackageTypesRefreshPost();
        if (error) {
            console.error(error);
        }
        await fetchPackages();
    };

    const handleEditPrice = (id: string) => {
        const packageWithPrices = tableData.find(pkg => pkg.id === id);
        if (packageWithPrices) {
            setSelectedPackage(packageWithPrices);
            setIsPriceDialogOpen(true);
        }
    };

    const PackageForm: React.FC<{ pkg?: PackageType }> = () => {
        const [isSubmitting, setIsSubmitting] = useState(false);
        const form = useForm<z.infer<typeof packageSchema>>({
            resolver: zodResolver(packageSchema),
            defaultValues: {
                name: selectedPackage?.name ?? '',
            },
        });

        const onSubmit = async (values: z.infer<typeof packageSchema>) => {
            try {
                setIsSubmitting(true);
                if (!selectedPackage?.id) {
                    return;
                }

                const { error } = await setNameV2WmsPackageTypesIdPut({
                    path: {
                        id: selectedPackage.id,
                    },
                    query: {
                        name: values.name,
                    },
                });

                if (error) {
                    console.error('Failed to update package:', error);
                    return;
                }

                await fetchPackages();
                setIsDialogOpen(false);
            } catch (error) {
                console.error('Error submitting form:', error);
            } finally {
                setIsSubmitting(false);
            }
        };

        return (
            <Form {...form}>
                <form 
                    onSubmit={form.handleSubmit(onSubmit)} 
                    className="space-y-8"
                >
                    <FormField
                        control={form.control}
                        name="name"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Name</FormLabel>
                                <FormControl>
                                    <Input placeholder="Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? "Updating..." : "Update Package"}
                    </Button>
                </form>
            </Form>
        );
    };

    const PriceForm: React.FC<{ pkg: PackageType }> = ({ pkg }) => {
        const [isSubmitting, setIsSubmitting] = useState(false);
        const [customers, setCustomers] = useState<any[]>([]);

        useEffect(() => {
            const fetchCustomers = async () => {
                const { data } = await readManyV2WmsInvoiceCustomersGet();
                setCustomers(data || []);
            };
            fetchCustomers();
        }, []);

        useEffect(() => {
        }, [pkg.prices]);

        const form = useForm<z.infer<typeof priceSchema>>({
            resolver: zodResolver(priceSchema),
            defaultValues: {
                entries: pkg.prices?.length > 0 
                    ? pkg.prices.map(price => ({
                        customerIds: [price.invoice_customer_id],
                        price: String(price.price),
                        isExisting: true
                    }))
                    : [{ customerIds: [], price: '', isExisting: false }]
            },
        });

        const { fields, append, remove } = useFieldArray({
            control: form.control,
            name: "entries",
        });

        const onSubmit = async (values: z.infer<typeof priceSchema>) => {
            try {
                setIsSubmitting(true);
                
                const updatePromises = values.entries.map(entry => 
                    updateV2WmsRulesPackageTypePut({
                        body: {
                            package_type_id: pkg.id,
                            invoice_customer_id: entry.customerIds[0],
                            price: parseFloat(entry.price),
                        }
                    })
                );

                const results = await Promise.all(updatePromises);
                const errors = results.filter(result => result.error);

                if (errors.length > 0) {
                    console.error('Failed to update some prices:', errors);
                    return;
                }

                await fetchPackages();
                setIsPriceDialogOpen(false);
            } catch (error) {
                console.error('Error submitting form:', error);
            } finally {
                setIsSubmitting(false);
            }
        };

        return (
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                    <div className="space-y-4">
                        {fields.map((field, index) => (
                            <div key={field.id} className="flex items-center space-x-4">
                                <FormField
                                    control={form.control}
                                    name={`entries.${index}.customerIds`}
                                    render={({ }) => (
                                        <FormItem className="flex-1">
                                            <FormLabel>Customer</FormLabel>
                                            <FormControl>
                                                <Controller
                                                    name={`entries.${index}.customerIds`}
                                                    control={form.control}
                                                    render={({ field }) => (
                                                        <Select
                                                            isMulti={false}
                                                            options={customers.map((customer) => ({
                                                                value: customer.id,
                                                                label: customer.name,
                                                            }))}
                                                            value={customers
                                                                .filter(c => field.value.includes(c.id))
                                                                .map(c => ({
                                                                    value: c.id,
                                                                    label: c.name,
                                                                }))
                                                            }
                                                            onChange={(newValue: any) => {
                                                                field.onChange([newValue.value]);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name={`entries.${index}.price`}
                                    render={({ field }) => (
                                        <FormItem className="flex-1">
                                            <FormLabel>Price</FormLabel>
                                            <FormControl>
                                                <Input 
                                                    type="number" 
                                                    step="0.01" 
                                                    placeholder="0.00" 
                                                    {...field} 
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                {!field.isExisting && (
                                    <Button 
                                        type="button" 
                                        onClick={() => remove(index)} 
                                        variant="ghost" 
                                        className="p-0 h-10 w-10 self-end mb-6"
                                    >
                                        <XCircleIcon className="h-5 w-5" />
                                    </Button>
                                )}
                            </div>
                        ))}
                    </div>

                    <div className="flex justify-between mt-6">
                        <Button
                            type="button"
                            variant="outline"
                            onClick={() => append({ customerIds: [], price: '', isExisting: false })}
                        >
                            Add Different Price
                        </Button>
                        <Button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Updating..." : "Save Prices"}
                        </Button>
                    </div>
                </form>
            </Form>
        );
    };

    const columns = [
        {
            accessorKey: 'name',
            header: "Rails Name",
            cell: ({ row }: { row: any }) => (
                <div className="flex items-center space-x-2">
                    <span className="w-[200px] whitespace-normal">
                        {row.original.name || '-'}
                    </span>
                    <Button variant="ghost" size="sm" onClick={() => handleEditPackage(row.original)}>
                        <PencilIcon className="h-4 w-4" />
                    </Button>
                </div>
            ),
            enableGlobalFilter: true,
        },
        {
            accessorKey: 'wms_package_name',
            header: "WMS Reference Name",
            cell: ({ row }: { row: any }) => (
                <span className="max-w-[500px] text-wrap">
                    {row.original.wms_package_name || '-'}
                </span>
            ),
            enableGlobalFilter: true,
        },
        {
            accessorKey: 'length',
            header: "Length",
            cell: ({ row }: { row: any }) => (
                <span className="max-w-[500px] text-wrap">
                    {row.original.length != null ? Number(row.original.length).toFixed(2) : '-'}
                </span>
            ),
            enableSorting: true,
        },
        {
            accessorKey: 'width',
            header: "Width",
            cell: ({ row }: { row: any }) => (
                <div className="w-[100px]">
                    {row.getValue('width') != null ? Number(row.getValue('width')).toFixed(2) : '-'}
                </div>
            ),
            enableSorting: true,
            enableHiding: true,
        },
        {
            accessorKey: 'height',
            header: "Height",
            cell: ({ row }: { row: any }) => (
                <div className="w-[100px]">
                    {row.getValue('height') != null ? Number(row.getValue('height')).toFixed(2) : '-'}
                </div>
            ),
            enableSorting: true,
            enableHiding: true,
        },
        {
            accessorKey: 'price',
            header: "Price",
            cell: ({ row }: { row: any }) => {
                const priceCount = row.original.prices?.length || 0;
                if (priceCount === 0) {
                    return (
                        <Button 
                            variant="ghost" 
                            size="sm" 
                            onClick={() => handleEditPrice(row.original.id)}
                        >
                            Set Price
                        </Button>
                    );
                }
                return (
                    <div className="text-sm">
                        {priceCount} price{priceCount > 1 ? 's' : ''} set
                    </div>
                );
            },
        },
    ];

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                        <h1 className="text-2xl font-bold text-rails-dark-blue">Packages</h1>
                        <h2 className="text-xs text-rails-dark-blue">Manage packages used on invoices.</h2>
                        <div className="container mx-auto py-4">
                            {tableData && (
                                <DataTable
                                    loading={isLoading}
                                    data={tableData}
                                    columns={columns}
                                    ActionButton={
                                        <Button 
                                            className={isLoading ? 'animate-pulse' : ''} 
                                            onClick={handleRefresh}
                                        >
                                            {isLoading ? 'Refreshing...' : 'Refresh'}
                                        </Button>
                                    }
                                    showActionButtonInToolbar={true}
                                    isDownloadable
                                    downloadData={tableData}
                                    tableName="packages"
                                    collapsible={true}
                                    collapsibleContent={(props) => <PriceCollapsibleContent {...props} onEditPrice={handleEditPrice} />}
                                />
                            )}
                        </div>
                    </div>
                </main>
            </div>
            
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogContent>
                    <PackageForm />
                </DialogContent>
            </Dialog>

            <Dialog open={isPriceDialogOpen} onOpenChange={setIsPriceDialogOpen}>
                <DialogContent>
                    {selectedPackage && (
                        <>
                            <DialogHeader>
                                <DialogTitle>Edit Prices</DialogTitle>
                            </DialogHeader>
                            <PriceForm pkg={selectedPackage} />
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Packages;
