/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect, useRef } from 'react';
import cronstrue from 'cronstrue';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Plus } from 'lucide-react';
import { UsersIcon, PencilSquareIcon, AdjustmentsHorizontalIcon, ArchiveBoxXMarkIcon, GlobeAltIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { Switch } from '@/components/ui/switch';
import Select from 'react-select';
import { getAllStorageLocationsStorageTypesGet, getAllStorageChargesGet, getInvoiceCustomersGet, putStorageChargesIdPut, postStorageChargesPost, deleteStorageChargesIdDelete } from '@/client/services.gen';
import type { StorageCharge, Location, Customer } from '@/client/types.gen';
import CronSchedule from '@/components/common/cronSchedule';
import LoadingSpinner from '@/components/ui/loading-spinner';

export const storageChargeType = [
    {
        label: 'per Location Rate', 
        value: 'PER_LOCATION',
    },
];

export const volumeUnit = [
    {
        label: 'cu inch',
        value: 'CUBIC_INCH',
    },
    {
        label: 'cu feet',
        value: 'CUBIC_FEET', 
    },
    {
        label: 'cu centimeter',
        value: 'CUBIC_CENTIMETER',
    },
    {
        label: 'cu meter',
        value: 'CUBIC_METER',
    },
];

const StorageFees = ({ showCreate = true }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState<StorageCharge[]>([]);
    const [locationData, setLocationData] = useState<Location[]>([]);
    const [customerData, setCustomerData] = useState<Customer[]>([]);
    const [selectedStorageFee, setSelectedStorageFee] = useState<StorageCharge | null>(null);
    const [open, setOpen] = useState(false);
    const [cronString, setCronString] = useState();
    const [isCreateMode, setIsCreateMode] = useState(true);
    const cronValidationRef = useRef();

    const dropDownSchema = z.object({
        label: z.string(),
        value: z.string(),
    });

    const storageFeeSchema = z.object({
        name: z.string().trim().min(3, { message: 'Required' }),
        location_type_ids: z.array(dropDownSchema).optional(),
        all_customers: z.boolean(),
        all_locations: z.boolean(), 
        customer_ids: z.array(dropDownSchema).optional(),
        exclude_empty_locations_from_charge: z.boolean(),
        charge_rate_type: dropDownSchema,
        charge_amount: z.coerce.number().nullish(),
        charge_amount_unit: z.array(dropDownSchema).optional(),
    });

    const formProps = useForm({
        resolver: zodResolver(storageFeeSchema),
        defaultValues: {
            name: selectedStorageFee?.name || '',
            location_type_ids: selectedStorageFee?.location_types?.map(lt => ({
                label: lt.name,
                value: lt.id
            })) || [],
            all_customers: selectedStorageFee?.all_customers || false,
            all_locations: selectedStorageFee?.all_locations || false,
            customer_ids: selectedStorageFee?.customers?.map(c => ({
                label: c.name,
                value: c.id
            })) || [],
            exclude_empty_locations_from_charge: selectedStorageFee?.exclude_empty_locations_from_charge || false,
            charge_rate_type: selectedStorageFee ? {
                label: storageChargeType.find(t => t.value === selectedStorageFee.charge_type)?.label,
                value: selectedStorageFee.charge_type
            } : null,
            charge_amount: selectedStorageFee?.charge_amount || 0,
            charge_amount_unit: selectedStorageFee?.charge_amount_unit?.map(u => ({
                label: volumeUnit.find(vu => vu.value === u)?.label,
                value: u
            })) || [],
        }
    });

    const { handleSubmit, reset, control, watch, getValues } = formProps;

    const fetchStorageFees = async () => {
        const { data, error } = await getAllStorageChargesGet();
        if (error) {
            console.error('Failed to fetch storage fees:', error);
        } else {
            setTableData(data || []);
        }
    };

    const fetchLocationTypes = async () => {
        const { data, error } = await getAllStorageLocationsStorageTypesGet();
        if (error) {
            console.error('Failed to fetch storage types:', error);
        } else if (data && Array.isArray(data)) {
            const filteredData = data.map((item) => ({
                label: item.name,
                value: item.id,
                id: item.id,
                name: item.name
            }));
            setLocationData(filteredData || []);
        }
    };

    const fetchCustomers = async () => {
        const { data, error } = await getInvoiceCustomersGet();
        if (error) {
            console.error('Failed to fetch customers:', error);
        } else if (data) {
            const filteredData = data
                .filter((customer) => customer.active === true)
                .filter((customer) => customer.invoice_customer_id === null)
                .map((item) => ({
                    label: item.name,
                    value: item.id,
                    id: item.id,
                    name: item.name,
                    active: item.active
                }));
            setCustomerData(filteredData);
        }
    };

    const openStorageFeesView = (row: { original: { id: string } }) => {
        const selectedFee = tableData.find((data) => data.id === row.original.id) || null;
        setSelectedStorageFee(selectedFee);
        setIsCreateMode(false);
        setCronString(selectedFee?.charge_calculation_cadence);
        reset({
            name: selectedFee?.name || '',
            location_type_ids: selectedFee?.location_types?.map(lt => ({
                label: lt.name,
                value: lt.id
            })) || [],
            all_customers: selectedFee?.all_customers || false,
            all_locations: selectedFee?.all_locations || false,
            customer_ids: selectedFee?.customers?.map(c => ({
                label: c.name,
                value: c.id
            })) || [],
            exclude_empty_locations_from_charge: selectedFee?.exclude_empty_locations_from_charge || false,
            charge_rate_type: selectedFee ? {
                label: storageChargeType.find(t => t.value === selectedFee.charge_type)?.label,
                value: selectedFee.charge_type
            } : null,
            charge_amount: selectedFee?.charge_amount || 0,
            charge_amount_unit: selectedFee?.charge_amount_unit?.map(u => ({
                label: volumeUnit.find(vu => vu.value === u)?.label,
                value: u
            })) || [],
        });
        setOpen(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([
                fetchStorageFees(),
                fetchLocationTypes(),
                fetchCustomers()
            ]);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    const onSubmit = async (values) => {
        setIsLoading(true);
        try {
            if (isCreateMode) {
                await postStorageChargesPost({
                    body: {
                        name: values.name,
                        location_type_ids: values.location_type_ids.map((location: { value: string }) => location.value),
                        all_customers: values.all_customers,
                        all_locations: values.all_locations,
                        customer_ids: values.customer_ids.map((customer: { value: string }) => customer.value),
                        exclude_empty_locations_from_charge: values.exclude_empty_locations_from_charge,
                        charge_type: values.charge_rate_type?.value,
                        charge_amount: values.charge_amount,
                        charge_calculation_cadence: cronString,
                        active: true,
                    },
                });
            } else {
                await putStorageChargesIdPut({
                    path: { id: selectedStorageFee?.id || '' },
                    body: {
                        name: values.name,
                        location_type_ids: values.location_type_ids.map((location: { value: string }) => location.value),
                        all_customers: values.all_customers,
                        all_locations: values.all_locations,
                        customer_ids: values.customer_ids.map((customer: { value: string }) => customer.value),
                        exclude_empty_locations_from_charge: values.exclude_empty_locations_from_charge,
                        charge_type: values.charge_rate_type?.value,
                        charge_amount: values.charge_amount,
                        charge_calculation_cadence: cronString,
                        active: true,
                    },
                });
            }
            fetchStorageFees();
            setIsLoading(false);
            setOpen(false);
        } catch (error) {
            console.error('Failed to save storage fee:', error);
            setIsLoading(false);
        }
    };

    const onDelete = async (id: string) => {
        try {
            await deleteStorageChargesIdDelete({ path: { id } });
            fetchStorageFees();
            setOpen(false);
        } catch (error) {
            console.error('Failed to delete storage fee:', error);
        }
    };

    const columns = [
        {
            accessorKey: 'name',
            header: 'Name',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px] font-medium hover:underline" onClick={() => openStorageFeesView(row)}>
                    <a href="#">{row.getValue('name')}</a>
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_type',
            header: 'Charge Type',
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">{storageChargeType.find((type) => type.value === row.getValue('charge_type'))?.label}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_amount',
            header: 'Charge',
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">${parseFloat(row.getValue('charge_amount'))?.toFixed(2)}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'location_types',
            header: 'Location Type',
            cell: ({ row }: { row: any }) => {
                const locations = row.getValue('location_types');
                if (locations?.length > 0) {
                    const locationLabel = locations.map((location: { name: string }) => location.name);
                    const locationLabelsJoined = locationLabel.join(', ');
                    return <div className="customer-list">{locationLabelsJoined}</div>;
                }
                return 'All';
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'customers',
            header: 'Customers',
            cell: ({ row }: { row: any }) => {
                const customers = row.getValue('customers');
                if (customers?.length > 0) {
                    const customerLabels = customers.map((customer: { name: string }) => customer.name);
                    const customerLabelsJoined = customerLabels.join(', ');
                    return <div className="customer-list">{customerLabelsJoined}</div>;
                }
                return 'All';
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_calculation_cadence',
            header: 'Cadence',
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">
                        {row.getValue('charge_calculation_cadence') && cronstrue.toString(row.getValue('charge_calculation_cadence'), { verbose: true })}
                    </span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
    ];

    const DataTableComponent = () => (
        <DataTable
            loading={isLoading}
            data={tableData}
            columns={columns}
            ActionButton={showCreate ? 
                <Button onClick={() => {
                    setIsCreateMode(true);
                    setOpen(true);
                    reset();
                }} size="sm" className="ml-4 h-8 text-sm">
                    <Plus className="h-4 w-4 mr-2" />
                    Create Storage Fee
                </Button> 
                : null}
            isMultiSelectRows={false}
            showActionButtonInToolbar
        />
    );

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Storage Fees</h1>
                            <h2 className="text-xs text-rails-dark-blue">Manage storage fees.</h2>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="container mx-auto py-20">{tableData && <DataTableComponent />}</div>
                        </div>
                    </div>
                </main>
            </div>

            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent className="max-w-2xl">
                    <DialogHeader>
                        <DialogTitle>{isCreateMode ? 'Create New Storage Fee' : selectedStorageFee?.name}</DialogTitle>
                        <DialogDescription>
                            <Form {...formProps}>
                                <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 overflow-y-auto max-h-[70vh] p-4">
                                    <FormField
                                        control={control}
                                        name="name"
                                        render={({ field }) => (
                                            <FormItem className="mb-6">
                                                <FormLabel>
                                                    <div className="flex items-center">
                                                        <PencilSquareIcon className="h-4 w-4 mr-2" />
                                                        Name
                                                    </div>
                                                </FormLabel>
                                                <FormControl>
                                                    <Input className="p-2" placeholder="Enter pricing rule name" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={control}
                                        name="all_locations"
                                        render={({ field }) => (
                                            <FormItem className="mb-6">
                                                <FormLabel>
                                                    <div className="flex items-center">
                                                        <GlobeAltIcon className="h-4 w-4 mr-2" />
                                                        For all locations
                                                    </div>
                                                </FormLabel>
                                                <FormControl>
                                                    <div className="flex items-center space-x-4 p-2">
                                                        <Switch onCheckedChange={field.onChange} checked={field.value} />
                                                        <span>Apply to all locations?</span>
                                                    </div>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    {!getValues('all_locations') && (
                                        <FormField
                                            control={control}
                                            name="location_type_ids"
                                            render={({ field }) => (
                                                <FormItem className="mb-6">
                                                    <FormLabel>
                                                        <div className="flex items-center">
                                                            <AdjustmentsHorizontalIcon className="h-4 w-4 mr-2" />
                                                            Locations
                                                        </div>
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Select
                                                            {...field}
                                                            isMulti
                                                            options={locationData.map(location => ({
                                                                label: location.name,
                                                                value: location.id
                                                            }))}
                                                            placeholder="Locations"
                                                            className="p-2"
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#DBF3D8',
                                                                    primary: '#92BAA3',
                                                                },
                                                            })}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    )}

                                    <FormField
                                        control={control}
                                        name="all_customers"
                                        render={({ field }) => (
                                            <FormItem className="mb-6">
                                                <FormLabel>
                                                    <div className="flex items-center">
                                                        <GlobeAltIcon className="h-4 w-4 mr-2" />
                                                        For all customers
                                                    </div>
                                                </FormLabel>
                                                <FormControl>
                                                    <div className="flex items-center space-x-4 p-2">
                                                        <Switch onCheckedChange={field.onChange} checked={field.value} />
                                                        <span>Apply to all customers?</span>
                                                    </div>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    {!getValues('all_customers') && (
                                        <FormField
                                            control={control}
                                            name="customer_ids"
                                            render={({ field }) => (
                                                <FormItem className="mb-6">
                                                    <FormLabel>
                                                        <div className="flex items-center">
                                                            <UsersIcon className="h-4 w-4 mr-2" />
                                                            Customers
                                                        </div>
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Select
                                                            {...field}
                                                            isMulti
                                                            options={customerData.map(customer => ({
                                                                label: customer.name,
                                                                value: customer.id
                                                            }))}
                                                            placeholder="Customer Names"
                                                            className="p-2"
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#DBF3D8',
                                                                    primary: '#92BAA3',
                                                                },
                                                            })}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    )}

                                    <div className="space-y-4 mb-6">
                                        <FormLabel>
                                            <div className="flex items-center">
                                                <AdjustmentsHorizontalIcon className="h-4 w-4 mr-2" />
                                                Charging Cadence
                                            </div>
                                        </FormLabel>
                                        <CronSchedule
                                            className="mt-2 p-2"
                                            setCronString={setCronString}
                                            cronValidationRef={cronValidationRef}
                                            initialCronString={selectedStorageFee?.charge_calculation_cadence}
                                            allowSetHourly={false}
                                        />
                                    </div>

                                    <FormField
                                        control={control}
                                        name="exclude_empty_locations_from_charge"
                                        render={({ field }) => (
                                            <FormItem className="mb-6">
                                                <FormLabel>
                                                    <div className="flex items-center">
                                                        <ArchiveBoxXMarkIcon className="h-4 w-4 mr-2" />
                                                        Exclude empty locations from charge?
                                                    </div>
                                                </FormLabel>
                                                <FormControl>
                                                    <div className="flex items-center space-x-4 p-2">
                                                        <Switch onCheckedChange={field.onChange} checked={field.value} />
                                                        <span>If no items are present at a location, should we exclude it from storage charges?</span>
                                                    </div>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={control}
                                        name="charge_rate_type"
                                        render={({ field }) => (
                                            <FormItem className="mb-6">
                                                <FormLabel>
                                                    <div className="flex items-center">
                                                        <CurrencyDollarIcon className="h-4 w-4 mr-2" />
                                                        Charging Rates
                                                    </div>
                                                </FormLabel>
                                                <FormControl>
                                                    <Select
                                                        {...field}
                                                        options={storageChargeType as readonly { label: string | undefined; value: StorageChargeTypes }[]}
                                                        placeholder="Charging Rate Type"
                                                        className="p-2"
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#DBF3D8',
                                                                primary: '#92BAA3',
                                                            },
                                                        })}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    {watch('charge_rate_type')?.value === 'PER_LOCATION' && (
                                        <FormField
                                            control={control}
                                            name="charge_amount"
                                            render={({ field }) => (
                                                <FormItem className="mb-6">
                                                    <FormLabel>Per occupied location</FormLabel>
                                                    <FormControl>
                                                        <Input className="p-2" placeholder="0" type="number" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    )}

                                    {watch('charge_rate_type')?.value === 'PER_UNIT_VOLUME' && (
                                        <div className="space-y-4 mb-6">
                                            <FormLabel>
                                                <div className="flex items-center">
                                                    <AdjustmentsHorizontalIcon className="h-4 w-4 mr-2" />
                                                    Volume Used
                                                </div>
                                            </FormLabel>
                                            <span>Fixed Cost for volume used.</span>
                                            <div className="flex space-x-2">
                                                <div className="w-1/2">
                                                    <FormField
                                                        control={control}
                                                        name="charge_amount"
                                                        render={({ field }) => (
                                                            <FormItem>
                                                                <FormControl>
                                                                    <Input className="p-2" placeholder="0" type="number" {...field} />
                                                                </FormControl>
                                                                <FormMessage />
                                                            </FormItem>
                                                        )}
                                                    />
                                                </div>
                                                <div className="w-1/2">
                                                    <FormField
                                                        control={control}
                                                        name="charge_amount_unit"
                                                        render={({ field }) => (
                                                            <FormItem>
                                                                <FormControl>
                                                                    <Select
                                                                        {...field}
                                                                        isMulti
                                                                        options={volumeUnit}
                                                                        placeholder="Per cu in, cu ft, cu cm, cu m."
                                                                        className="p-2"
                                                                        theme={(theme) => ({
                                                                            ...theme,
                                                                            colors: {
                                                                                ...theme.colors,
                                                                                primary25: '#DBF3D8',
                                                                                primary: '#92BAA3',
                                                                            },
                                                                        })}
                                                                    />
                                                                </FormControl>
                                                                <FormMessage />
                                                            </FormItem>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="flex justify-between pt-4">
                                        <Button type="submit" disabled={isLoading} className="px-6 py-2">
                                            {isLoading ? (
                                                <>
                                                    <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                                                    {isCreateMode ? 'Creating' : 'Saving'}
                                                </>
                                            ) : (
                                                isCreateMode ? 'Create' : 'Save'
                                            )}
                                        </Button>

                                        {!isCreateMode && (
                                            <Button 
                                                type="button" 
                                                className="bg-white text-rails-dark-green px-6 py-2" 
                                                onClick={() => onDelete(selectedStorageFee?.id || '')}
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    </div>
                                </form>
                            </Form>
                        </DialogDescription>
                    </DialogHeader>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default StorageFees;
