import React, { useState, useEffect } from 'react';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Plus } from 'lucide-react';
import {
    PencilIcon,
} from '@heroicons/react/24/outline';
import { getIntegrationCarrierGet, postIntegrationCarrierPost, putIntegrationCarrierCarrierIdPut, readManyV2WmsShippingMethodsGet, getServiceLevelsCarrierBillsServiceLevelsGet, getCarriersCarrierBillsCarriersGet, setServiceLevelV2WmsShippingMethodsIdServiceLevelIdPut } from '@/client/services.gen';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import { Form, FormField, FormItem, FormControl, FormMessage, FormLabel } from '@/components/ui/form';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import type { CarrierAccountConnection, ShippingMethod, backend__db__schemas__carrier_service_guide__ServiceLevel, backend__db__schemas__carrier_invoice__Carrier } from '@/client/types.gen';
import Select from 'react-select';

interface CarrierIntegration {
    id: string;
    carrier_name: string;
    carrier_connection_name: string;
    is_active: boolean;
    account_number: string;
    username: string;
    password: string;
    facility_id?: string;
}

const carrierSchema = z.object({
    carrier_id: z.string().min(1, 'Carrier is required'),
    carrier_connection_name: z.string().min(1, 'Connection name is required'),
    is_active: z.boolean(),
    account_number: z.string().min(1, 'Account number is required'),
    username: z.string().min(1, 'Username is required'),
    password: z.string().min(1, 'Password is required'),
    facility_id: z.string().optional(),
});

const serviceLevelMappingSchema = z.object({
    service_level_id: z.string().min(1, 'Service level is required'),
    shipping_method_id: z.string().min(1, 'Shipping method is required')
});

const CarrierIntegrations: React.FC = () => {
    //const [carrierConnections, setCarrierConnections] = useState<CarrierAccountConnection[]>([]);
    const [carrierConnections, setCarrierConnections] = useState<CarrierAccountConnection[]>([]);
    const [serviceLevels, setServiceLevels] = useState<backend__db__schemas__carrier_service_guide__ServiceLevel[]>([]);
    const [internalCarriers, setInternalCarriers] = useState<backend__db__schemas__carrier_invoice__Carrier[]>([]);
    const [mappings, setMappings] = useState<ShippingMethod[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isMappingDialogOpen, setIsMappingDialogOpen] = useState(false);
    const [selectedCarrier, setSelectedCarrier] = useState<CarrierIntegration | null>(null);
    const [selectedServiceLevel, setSelectedServiceLevel] = useState<backend__db__schemas__carrier_service_guide__ServiceLevel | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const carrierList = [
        { value: "0154cf3d-86d7-4895-a85b-6988aac41335", label: "endicia" },
        { value: "1826201c-77bd-40fd-8de6-6efb822d4864", label: "dhl_express" },
        { value: "2fc704b6-b406-494d-8b82-dfcb080d54ea", label: "stamps_com" },
        { value: "367b9e30-ccb8-4764-85c8-fa6feb8232f6", label: "dhl_global_mail" },
        { value: "51406354-503f-475d-b190-2b98d743176a", label: "amazon_shipping" },
        { value: "704cf9e0-38d8-4a04-a765-f1be6fc3f823", label: "shippo__usps" },
        { value: "74fde414-4297-4792-982a-33fc9785e251", label: "ups" },
        { value: "76079a96-1ed3-42b9-ba12-97e58c066897", label: "usps" },
        { value: "9044bded-f78b-4d62-968f-096246533b8c", label: "passport" },
        { value: "ae1e6af1-f5fc-4814-bf94-370c287972bd", label: "fedex" },
        { value: "d475a2e4-364f-483e-b813-3480b7528496", label: "apc" },
        { value: "db757f31-24ed-41c3-921d-d08645935911", label: "dhl_ecommerce" },
        { value: "dbc9c50a-4ae0-45e9-b838-88f1ce4f0889", label: "ups_walleted" },
        { value: "e49355a7-ab9c-4b9a-9d41-545f747d212b", label: "ups - mailinnovations" },
    ]

    useEffect(() => {
        fetchCarriers();
        fetchMappings();
        fetchServiceLevels();
        fetchInternalCarriers();
    }, []);

    const fetchCarriers = async () => {
        setLoading(true);
        const { data, error } = await getIntegrationCarrierGet();
        if (error) {
            console.error('Failed to fetch carriers:', error);
            setError('Failed to fetch carriers');
        } else {
            setCarrierConnections(data!);
        }
        setLoading(false);
    };

    const fetchServiceLevels = async () => {
        const { data, error } = await getServiceLevelsCarrierBillsServiceLevelsGet();
        if (error) {
            console.error('Failed to fetch service levels:', error);
            setError('Failed to fetch service levels');
        } else {
            setServiceLevels(data!);
        }
    }

    const fetchInternalCarriers = async () => {
        const { data, error } = await getCarriersCarrierBillsCarriersGet();
        if (error) {
            console.error('Failed to fetch internal carriers:', error);
            setError('Failed to fetch internal carriers');
        } else {
            setInternalCarriers(data!);
        }
    }

    const fetchMappings = async () => {
        const { data, error } = await readManyV2WmsShippingMethodsGet();
        if (error) {
            console.error('Failed to fetch mappings:', error);
            setError('Failed to fetch mappings');
        } else {
            setMappings(data!);
        }
    }

    const columns = [
        {
            accessorKey: 'carrier_name',
            header: 'Carrier Name',
            cell: ({ row }: { row: any }) => (
                <span className="w-[200px] font-medium hover:underline" onClick={() => handleEditCarrier(row.original)}>
                    {row.original.carrier_name}
                </span>
            ),
        },
        { accessorKey: 'carrier_connection_name', header: 'Connection Name' },
        {
            accessorKey: 'is_active',
            header: 'Active',
            cell: ({ row }: { row: any }) => <Switch checked={row.original.is_active} disabled />,
        },
    ];

    const mappingColumns = [
        { accessorKey: 'name', header: 'WMS Reported Service Level' },
        { accessorKey: 'carrier', header: 'WMS Reported Carrier' },
        { 
            accessorKey: 'service_level_id', 
            header: 'Rails Reported Service Level',
            cell: ({ row }: { row: any }) => {
                const serviceLevel = serviceLevels.find(sl => sl.id === row.original.service_level_id);
                return serviceLevel?.name || '-';
            }
        },
        {
            id: 'actions',
            cell: ({ row }: { row: any }) => (
                <Button variant="ghost" size="sm" onClick={() => handleMapServiceLevel(row.original)}>
                    <PencilIcon className="h-4 w-4" />
                </Button>
            ),
        }
    ];

    const handleCreateCarrier = () => {
        setSelectedCarrier(null);
        setIsDialogOpen(true);
    };

    const handleEditCarrier = (carrier: CarrierIntegration) => {
        setSelectedCarrier(carrier);
        setIsDialogOpen(true);
    };

    const handleMapServiceLevel = (serviceLevel: backend__db__schemas__carrier_service_guide__ServiceLevel) => {
        setSelectedServiceLevel(serviceLevel);
        setIsMappingDialogOpen(true);
    };

    const onSubmit = async (values: z.infer<typeof carrierSchema>) => {
        try {
            const selectedCarrierOption = carrierList.find(c => c.value === values.carrier_id);
            const postData = {
                ...values,
                carrier_name: selectedCarrierOption?.label
            };
            if (selectedCarrier) {
                await putIntegrationCarrierCarrierIdPut({ path: { carrierId: selectedCarrier.id }, body: postData });
                setSuccessMessage('Carrier updated successfully');
            } else {
                await postIntegrationCarrierPost({ body: postData });
                setSuccessMessage('Carrier created successfully');
            }
            setIsDialogOpen(false);
            fetchCarriers();
        } catch (err) {
            setError(selectedCarrier ? 'Failed to update carrier' : 'Failed to create carrier');
            console.error(err);
        }
    };

    const onSubmitMapping = async (values: z.infer<typeof serviceLevelMappingSchema>) => {

        try {
            await setServiceLevelV2WmsShippingMethodsIdServiceLevelIdPut({
                path: {
                    id: values.shipping_method_id,
                    service_level_id: values.service_level_id
                }
            });
            await fetchMappings();
            setSuccessMessage('Service level mapped successfully');
            setIsMappingDialogOpen(false);
        } catch (err) {
            setError('Failed to map service level');
            console.error(err);
        }
    };

    const ServiceLevelMappingForm: React.FC<{ serviceLevel?: backend__db__schemas__carrier_invoice__Carrier }> = ({ serviceLevel }) => {
        const [selectedCarrierId, setSelectedCarrierId] = useState<string>('');
        
        const form = useForm<z.infer<typeof serviceLevelMappingSchema>>({
            resolver: zodResolver(serviceLevelMappingSchema),
            defaultValues: {
                shipping_method_id: serviceLevel?.id || '',
                service_level_id: ''
            }
        });
        const carrierOptions = internalCarriers.map(carrier => ({
            value: carrier.id,
            label: carrier.name
        }));

        const shippingMethodOptions = serviceLevels
            .filter(sl => selectedCarrierId && sl.carrier_id === selectedCarrierId)
            .map(method => ({
                value: method.id,
                label: method.name
            }));

        return (
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmitMapping)} className="space-y-8">
                    <FormItem>
                        <FormLabel>Current WMS Service Level</FormLabel>
                        <FormControl>
                            <Input value={serviceLevel?.name || ''} disabled />
                        </FormControl>
                    </FormItem>

                    <FormItem>
                        <FormLabel>Select Carrier</FormLabel>
                        <Select
                            options={carrierOptions}
                            onChange={(val) => {
                                setSelectedCarrierId(val?.value || '');
                            }}
                            isClearable
                            styles={{
                                menu: (base) => ({
                                    ...base,
                                    position: 'relative',
                                    zIndex: 9999,
                                    width: '100%'
                                }),
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999
                                })
                            }}
                            menuPosition="relative"
                        />
                    </FormItem>

                    <FormField
                        control={form.control}
                        name="service_level_id"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Shipping Method</FormLabel>
                                <FormControl>
                                    <Controller
                                        name="service_level_id"
                                        control={form.control}
                                        render={({ field }) => (
                                            <Select
                                                options={shippingMethodOptions}
                                                value={shippingMethodOptions.find(m => m.value === field.value)}
                                                onChange={(val) => field.onChange(val?.value)}
                                                isDisabled={!selectedCarrierId}
                                                styles={{
                                                    menu: (base) => ({
                                                        ...base,
                                                        position: 'relative',
                                                        zIndex: 9999,
                                                        width: '100%'
                                                    }),
                                                    menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999
                                                    })
                                                }}
                                                menuPosition="relative"
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button type="submit">Map Service Level</Button>
                </form>
            </Form>
        );
    };

    const CarrierForm: React.FC<{ carrier?: CarrierIntegration }> = ({ carrier }) => {
        const form = useForm<z.infer<typeof carrierSchema>>({
            resolver: zodResolver(carrierSchema),
            defaultValues: carrier ? {
                ...carrier,
                carrier_id: carrierList.find(c => c.label === carrier.carrier_name)?.value || ''
            } : {
                carrier_id: '',
                carrier_connection_name: '',
                is_active: true,
                account_number: '',
                username: '',
                password: '',
                facility_id: '',
            },
        });

        return (
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                    <FormField
                        control={form.control}
                        name="carrier_id"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Carrier</FormLabel>
                                <FormControl>
                                    <Controller
                                        name="carrier_id"
                                        control={form.control}
                                        render={({ field }) => (
                                            <Select
                                                options={carrierList}
                                                value={carrierList.find(c => c.value === field.value)}
                                                onChange={(val) => field.onChange(val?.value)}
                                                styles={{
                                                    menu: (base) => ({
                                                        ...base,
                                                        position: 'relative',
                                                        zIndex: 9999,
                                                        width: '100%'
                                                    }),
                                                    menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999
                                                    })
                                                }}
                                                menuPosition="relative"
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="carrier_connection_name"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Connection Name</FormLabel>
                                <FormControl>
                                    <Input placeholder="Connection Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="is_active"
                        render={({ field }) => (
                            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                                <div className="space-y-0.5">
                                    <FormLabel className="text-base">Active</FormLabel>
                                </div>
                                <FormControl>
                                    <Switch checked={field.value} onCheckedChange={field.onChange} />
                                </FormControl>
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="account_number"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Account Number</FormLabel>
                                <FormControl>
                                    <Input placeholder="Account Number" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="username"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Username</FormLabel>
                                <FormControl>
                                    <Input placeholder="Username" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Password</FormLabel>
                                <FormControl>
                                    <Input type="password" placeholder="Password" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="facility_id"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Facility ID</FormLabel>
                                <FormControl>
                                    <Input placeholder="Facility ID" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button type="submit">{carrier ? 'Update' : 'Create'} Carrier</Button>
                </form>
            </Form>
        );
    };

    const AddCarrierButton = (
        <Button onClick={handleCreateCarrier}>
            <Plus className="mr-2 h-4 w-4" /> Carrier
        </Button>
    );

    return (
        <div className="container mx-auto p-8">
            {carrierConnections.length === 0 ? (
                <div className="flex flex-col items-center justify-center h-[calc(100vh-200px)]">
                    <h2 className="text-2xl font-semibold mb-4">Connect your first carrier</h2>
                    {AddCarrierButton}
                </div>
            ) : (
                <div>
                    <h1 className="text-xl font-semibold mb-4">Carrier Integrations</h1>
                    <DataTable columns={columns} data={carrierConnections} loading={loading} ActionButton={AddCarrierButton} showActionButtonInToolbar={true} showPagination={false} />
                    <h1 className="text-xl font-semibold mt-4">Service Level Mappings</h1>
                    <h2 className="text-sm text-gray-500 mb-4">Map your WMS service level representations to the Rail's service levels for simplified invoicing and reconciliation</h2>
                    <DataTable columns={mappingColumns} data={mappings} loading={loading} showPagination={true} />
                </div>
            )}

            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogContent className="h-auto relative">
                    <h2 className="text-lg font-semibold">Carrier Integration</h2>
                    <CarrierForm carrier={selectedCarrier || undefined} />
                </DialogContent>
            </Dialog>

            <Dialog open={isMappingDialogOpen} onOpenChange={setIsMappingDialogOpen}>
                <DialogContent>
                    <h2 className="text-lg font-semibold">Map Service Level</h2>
                    <ServiceLevelMappingForm serviceLevel={selectedServiceLevel || undefined} />
                </DialogContent>
            </Dialog>

            {error && <p className="text-red-500 mt-4">{error}</p>}
            {successMessage && <p className="text-green-500 mt-4">{successMessage}</p>}
        </div>
    );
};

export default CarrierIntegrations;