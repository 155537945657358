import { useState, useEffect } from 'react';
import { DataTable } from '@/components/ui/datatable/data-table';
import { getAllStorageLocationsStorageTypesGet } from '@/client/services.gen';
import { showUnits } from '@/components/invoices/products/productView';

interface StorageType {
    id: string;
    name: string;
    dimensions: {
        length: string;
        width: string;
        height: string;
        length_unit: string;
    };
}

const StorageTypes = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState<StorageType[]>([]);

    const fetchStorageTypes = async () => {
        setIsLoading(true);
        const { data, error } = await getAllStorageLocationsStorageTypesGet();
        if (error) {
            console.error('Failed to fetch storage types:', error);
        } else {
            setTableData(
                data!.map((item) => ({
                    ...item,
                    dimensions: {
                        ...item.dimensions,
                        length: String(item.dimensions.length),
                        width: String(item.dimensions.width),
                        height: String(item.dimensions.height),
                    },
                })),
            );
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchStorageTypes();
    }, []);

    const columns = [
        {
            accessorKey: 'name',
            header: 'Name',
            cell: ({ row }: { row: any }) => <div className="w-[200px] font-medium">{row.getValue('name')}</div>,
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'dimensions',
            header: 'Dimensions',
            cell: ({ row }: { row: any }) => (
                <div className="w-[100px] font-medium">
                    {row.getValue('dimensions') && (
                        <>
                            <span className="font-bold">L: </span>
                            {row.getValue('dimensions')?.length ?? '-'} {showUnits(row.getValue('dimensions')?.length_unit)}
                            <span className="font-bold"> H: </span>
                            {row.getValue('dimensions')?.height ?? '-'} {showUnits(row.getValue('dimensions')?.length_unit)}
                            <span className="font-bold"> W: </span>
                            {row.getValue('dimensions')?.width ?? '-'} {showUnits(row.getValue('dimensions')?.length_unit)}
                        </>
                    )}
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
    ];

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Storage Types</h1>
                            <h2 className="text-xs text-rails-dark-blue">Manage storage types.</h2>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="container mx-auto py-20">
                                {tableData && <DataTable loading={isLoading} data={tableData} columns={columns} isMultiSelectRows={false} tableName="storageTypes" />}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default StorageTypes;
