/* eslint-disable no-nested-ternary */
import { UsersIcon, AdjustmentsHorizontalIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { ScrollArea } from '../../common/scroll-area.tsx';
import { Separator } from '../../common/separator.tsx';

import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogFooter } from '../../common/dialog.tsx';
import { Table, TableHeader, TableHead, TableBody, TableRow, TableCell } from '../../common/table.tsx';

import { Button } from '@/components/ui/button';

import { Switch } from '../../common/switch.tsx';
import { Badge } from '../../common/badge.tsx';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '../../common/alert-dialog.tsx';
import { Card, CardContent, CardHeader, CardTitle } from '../../common/card.tsx';
import { Input } from '../../common/input.tsx';

import { deleteInvoiceRulesRuleIdDelete } from '@/client/services.gen';
import PricingRuleEdit from './pricingRuleEdit.tsx';
import { TierConditionCol, PricingMethodVariableCol, PricingMethodCol, PricingMethodMarginCol } from '@/consts/vars.ts';
import { Select as RegSelect, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../../common/select.tsx';
import { useMemo } from 'react';

interface PricingRuleViewProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    pricingRule: any;
    setPricingRule: (rule: any) => void;
    ruleConditionMetadata: any;
}

const PricingRuleView = ({ open, setOpen, pricingRule, setPricingRule, ruleConditionMetadata }: PricingRuleViewProps) => {
    
    const onDelete = (pricingRuleId: string) => {
        deleteInvoiceRulesRuleIdDelete({
            path: {
                ruleId: pricingRuleId
            }
        }).then(() => {
            setOpen(false);
        });
    };

    // Format the pricing rule data once for both view and edit
    const formattedPricingRule = useMemo(() => {
        if (!pricingRule) return null;


        const formattedPricingRule = {
            ...pricingRule,
            charge_type: {
                label: pricingRule.charge_type,
                value: pricingRule.charge_type_id
            },
            rule_conditions: pricingRule.rule_conditions?.map((condition: any) => {
                const metadata = ruleConditionMetadata.find((r: any) => r.col === condition.col);
                const stringValue = String(condition.value || '');
                
                let formattedValue;
                if (stringValue.includes(',')) {
                    // Handle array values
                    formattedValue = stringValue.split(',').map((val: string) => ({
                        label: metadata?.allowed_values?.[val.trim()] || val.trim(),
                        value: val.trim()
                    }));
                } else if (metadata?.allowed_values) {
                    // Handle single dropdown value
                    formattedValue = {
                        label: metadata.allowed_values[condition.value] || condition.value,
                        value: condition.value
                    };
                } else {
                    // Handle plain string/number value
                    formattedValue = stringValue;
                }

                return {
                    col: {
                        label: metadata?.name || condition.col,
                        value: condition.col
                    },
                    filter: {
                        label: condition.filter,
                        value: condition.filter
                    },
                    value: formattedValue
                };
            }),
            tiers: pricingRule.tiers?.map((tier: any) => ({
                ...tier,
                tier_conditions: tier.tier_conditions?.reduce((acc: any, condition: any) => {
                    // Assuming the first condition with >= is the start and <= is the end
                    if (condition.filter === '>=') {
                        acc.rangeStart = condition.value;
                    } else if (condition.filter === '<=') {
                        acc.rangeEnd = condition.value;
                    }
                    acc.rangeCol = condition.col;
                    return acc;
                }, { rangeCol: '', rangeStart: '', rangeEnd: '' }),
                pricing_choices: tier.pricing_choices?.map((choice: any) => ({
                    method: choice.method,
                    value: choice.value?.toString(),
                    col: choice.col
                }))
            }))
        };

        return formattedPricingRule;
    }, [pricingRule, ruleConditionMetadata]);

    if (!pricingRule) {
        return null;
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="max-w-4xl h-[90vh] overflow-hidden flex flex-col">
                <DialogHeader>
                    <DialogTitle className="text-3xl">{formattedPricingRule.name}</DialogTitle>
                    <DialogDescription>View pricing rule details below</DialogDescription>
                </DialogHeader>

                <ScrollArea className="flex-1 pr-4">
                    <div className="pt-4">
                        <div className="flex text-xl font-medium">
                            <GlobeAltIcon className="h-6 w-6 mr-2" />
                            Default
                        </div>
                        <div className="mt-2 flex items-center space-x-4">
                            <Switch checked={formattedPricingRule.default} disabled />
                        </div>
                    </div>

                    <div className="pt-8">
                        <div className="flex text-xl font-medium">
                            <UsersIcon className="h-6 w-6 mr-2" />
                            Customers
                        </div>
                        <ScrollArea className="overflow-y-auto max-h-72 rounded-md border px-4 mt-2 pb-2">
                            <div className="pt-2">
                                {formattedPricingRule.default && <div className="w-100 pr-2">This is a default rule, applies to all customers.</div>}
                                {!formattedPricingRule.customer_ids && !formattedPricingRule.default && <div className="w-100 pr-2">No assigned customers.</div>}
                                {formattedPricingRule.customer_ids?.map((customer: any, idx: number) => (
                                    <div key={idx}>
                                        <div className="w-100 pr-2">{customer.label}</div>
                                        {idx < formattedPricingRule.customer_ids.length - 1 && <Separator className="my-2" />}
                                    </div>
                                ))}
                            </div>
                        </ScrollArea>
                    </div>

                    <div className="pt-10">
                        <div className="flex text-xl font-medium">
                            <AdjustmentsHorizontalIcon className="h-6 w-6 mr-2" />
                            Details
                        </div>
                        <div>
                            <div className="mt-4 mb-2">
                                {!formattedPricingRule.rule_conditions && <span className="text-base text-gray-500">No conditions.</span>}
                                {formattedPricingRule.rule_conditions && <span className="text-base text-gray-500">Conditions</span>}
                            </div>
                        </div>
                        {formattedPricingRule.rule_conditions && (
                            <div>
                                <Table className="w-full">
                                    <TableHeader className="bg-slate-50">
                                        <TableRow>
                                            <TableHead>Condition</TableHead>
                                            <TableHead>Filter</TableHead>
                                            <TableHead>Value</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {formattedPricingRule.rule_conditions.map((ruleCondition: any, idx: number) => (
                                            <TableRow key={idx}>
                                                <TableCell className="pr-2 w-1/3">
                                                    <Badge variant="outline">
                                                        {ruleCondition.col.label}
                                                    </Badge>
                                                </TableCell>
                                                <TableCell className="pr-2 w-1/3">
                                                    <Badge>{ruleCondition.filter.label}</Badge>
                                                </TableCell>
                                                <TableCell className="pr-2 w-1/3">
                                                    {Array.isArray(ruleCondition.value) ? (
                                                        ruleCondition.value.map((val: any, idx: number) => (
                                                            <Badge key={idx} className="mt-2 mr-2" variant="outline">
                                                                {val.label}
                                                            </Badge>
                                                        ))
                                                    ) : (
                                                        <Badge variant="outline">
                                                            {typeof ruleCondition.value === 'object' ? ruleCondition.value.label : ruleCondition.value}
                                                        </Badge>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        )}
                        <div className="mt-12">
                            <span className="text-base text-gray-500">Pricing Type</span>
                            <Input className="bg-white w-72 mt-2" type="text" placeholder={formattedPricingRule.charge_type.label} disabled />
                        </div>
                    </div>

                    <div className="pt-10">
                        <div className="grid grid-rows-2">
                            <span className="text-base text-gray-500">Pricing Definition</span>
                            <div className="flex items-center grid grid-cols-5 pt-1 pb-2">
                                <div className="flex text-md col-span-4 whitespace-nowrap text-wrap">Should charges from each tier be summed up?</div>
                                <div className="flex col-span-1 justify-end">
                                    <Switch checked={formattedPricingRule.tiers_are_cumulative} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="mt-2">
                            {formattedPricingRule.tiers &&
                                formattedPricingRule.tiers.map((tier: any, tierIdx: number) => (
                                    <Card key={tierIdx} className="bg-gray-100 border-transparent shadow-none mb-2">
                                        <CardHeader>
                                            <CardTitle>
                                                <div className="flow-root">
                                                    <div className="float-left text-gray-700">Tier {tierIdx + 1}</div>
                                                </div>
                                            </CardTitle>
                                        </CardHeader>
                                        <CardContent>
                                            {Object.keys(tier.tier_conditions || {}).length > 0 && (
                                                <div className="mb-4 flex text-base">
                                                    <div className="mt-2 mr-2 vertical-align">
                                                        <span className="w-content">Tier defined as</span>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <RegSelect
                                                            className="bg-white whitespace-nowrap w-fit"
                                                            type="number"
                                                            defaultValue={TierConditionCol[tier.tier_conditions.rangeCol]}
                                                            value={TierConditionCol[tier.tier_conditions.rangeCol]}
                                                            disabled
                                                        >
                                                            <SelectTrigger className="bg-white w-fit">
                                                                <SelectValue placeholder={TierConditionCol[tier.tier_conditions.rangeCol]} />
                                                            </SelectTrigger>
                                                            <SelectContent>
                                                                <SelectGroup>
                                                                    <SelectItem value={TierConditionCol[tier.tier_conditions.rangeCol]}>
                                                                        {TierConditionCol[tier.tier_conditions.rangeCol]}
                                                                    </SelectItem>
                                                                </SelectGroup>
                                                            </SelectContent>
                                                        </RegSelect>
                                                        <div className="mx-2">between</div>
                                                        <Input className="bg-white w-20 text-center" type="text" placeholder={tier.tier_conditions.rangeStart} disabled />
                                                        <div className="mx-2">and</div>
                                                        <Input className="bg-white w-20 text-center" type="text" placeholder={tier.tier_conditions.rangeEnd} disabled />
                                                    </div>
                                                </div>
                                            )}
                                            {tier.pricing_choices_filter && (
                                                <div className="flex items-center text-base mb-2">
                                                    <span className="mr-2">Pricing defined as</span>
                                                    <div className="bg-white border rounded-md p-2">{tier.pricing_choices_filter} of</div>
                                                </div>
                                            )}
                                            <div className="flex flex-col space-y-2">
                                                {tier.pricing_choices &&
                                                    tier.pricing_choices.map((pricingChoice: any, pricingChoiceIdx: number) => (
                                                        <div key={pricingChoiceIdx} className="flex items-center space-x-2">
                                                            <RegSelect
                                                                className="bg-white whitespace-nowrap w-fit"
                                                                type="number"
                                                                defaultValue={PricingMethodCol[pricingChoice.method]}
                                                                value={PricingMethodCol[pricingChoice.method]}
                                                                disabled
                                                            >
                                                                <SelectTrigger className="bg-white whitespace-nowrap w-fit">
                                                                    <SelectValue placeholder={PricingMethodCol[pricingChoice.method]} />
                                                                </SelectTrigger>
                                                                <SelectContent>
                                                                    <SelectGroup>
                                                                        <SelectItem value={PricingMethodCol[pricingChoice.method]}>
                                                                            {PricingMethodCol[pricingChoice.method]}
                                                                        </SelectItem>
                                                                    </SelectGroup>
                                                                </SelectContent>
                                                            </RegSelect>
                                                            {!pricingChoice.method.includes('fixed') && (
                                                                <div className="flex items-center">
                                                                    {pricingChoice.method.includes('margin') && (
                                                                        <div className="ml-2 vertical-align">
                                                                            <span className="mr-2"> on </span>
                                                                        </div>
                                                                    )}
                                                                    <RegSelect
                                                                        className="bg-white whitespace-nowrap w-fit"
                                                                        type="number"
                                                                        defaultValue={
                                                                            pricingChoice.method.includes('margin')
                                                                                ? PricingMethodMarginCol[pricingChoice.col]
                                                                                : PricingMethodVariableCol[pricingChoice.col]
                                                                        }
                                                                        value={
                                                                            pricingChoice.method.includes('margin')
                                                                                ? PricingMethodMarginCol[pricingChoice.col]
                                                                                : PricingMethodVariableCol[pricingChoice.col]
                                                                        }
                                                                        disabled
                                                                    >
                                                                        <SelectTrigger className="bg-white w-fit">
                                                                            <SelectValue
                                                                                placeholder={
                                                                                    pricingChoice.method.includes('margin')
                                                                                        ? PricingMethodMarginCol[pricingChoice.col]
                                                                                        : PricingMethodVariableCol[pricingChoice.col]
                                                                                }
                                                                            />
                                                                        </SelectTrigger>
                                                                        <SelectContent>
                                                                            <SelectGroup>
                                                                                <SelectItem
                                                                                    value={
                                                                                        pricingChoice.method.includes('margin')
                                                                                            ? PricingMethodMarginCol[pricingChoice.col]
                                                                                            : PricingMethodVariableCol[pricingChoice.col]
                                                                                    }
                                                                                >
                                                                                    {pricingChoice.method.includes('margin')
                                                                                        ? PricingMethodMarginCol[pricingChoice.col]
                                                                                        : PricingMethodVariableCol[pricingChoice.col]}
                                                                                </SelectItem>
                                                                            </SelectGroup>
                                                                        </SelectContent>
                                                                    </RegSelect>
                                                                </div>
                                                            )}
                                                            <div className="ml-2">
                                                                <Input
                                                                    className="bg-white w-20"
                                                                    type="number"
                                                                    placeholder={pricingChoice.value?.toString()}
                                                                    specialType={
                                                                        pricingChoice.method == null ? '' : pricingChoice.method.includes('percent') ? 'percent' : 'dollar'
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </CardContent>
                                    </Card>
                                ))}
                        </div>
                    </div>
                </ScrollArea>

                <DialogFooter className="border-t-2 border-t-gray-50 pt-4 pb-4 mt-4">
                    <div className="flex justify-between w-full">
                        <AlertDialog>
                            <AlertDialogTrigger asChild>
                                <Button variant="outline">Delete</Button>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>Are you sure you want to delete {formattedPricingRule.name}?</AlertDialogTitle>
                                    <AlertDialogDescription>This action cannot be undone. This will permanently delete the rule.</AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                                    <AlertDialogAction onClick={() => onDelete(formattedPricingRule.id)}>Delete</AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                        <PricingRuleEdit 
                            ruleId={formattedPricingRule.id}
                            pricingRule={formattedPricingRule}
                            ruleConditionMetadata={ruleConditionMetadata}
                            setRuleId={setPricingRule}
                            setIsDialogOpen={setOpen}
                        />
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default PricingRuleView;
