import * as z from 'zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import PricingCategoryForm from './forms/pricingCategoryForm';
import { postInvoiceChargeTypesPost } from '@/client/services.gen';

const PricingCategoryCreate = ({ chargeLevels, isDialogOpen, setIsDialogOpen }) => {
    const [loading, setLoading] = useState(false);
    const pricingCategoryCreateFormDefaultValues = {
        name: '',
        charge_on_invoice: '',
        description: '',
        default: false,
        required: false,
        charge_level: '',
    };

    const dropDownSchema = z.object({
        label: z.string(),
        value: z.string(),
    });

    const pricingCategorySchema = z.object({
        name: z.string().trim().min(3, { message: 'Minimum of 3 characters required' }),
        charge_on_invoice: z.string().trim().min(3, { message: 'Minimum of 3 characters required' }),
        default: z.boolean().optional(),
        required: z.boolean().optional(),
        description: z.string().trim().min(3, { message: 'Minimum of 3 characters required' }),
        charge_level: dropDownSchema,
    });

    const formProps = useForm({
        resolver: zodResolver(pricingCategorySchema),
        defaultValues: pricingCategoryCreateFormDefaultValues,
    });

    const { handleSubmit, reset } = formProps;

    const onSubmit = (values) => {
        setLoading(true);
        // Extract just the value from the charge_level dropdown selection
        const submitValues = {
            ...values,
            charge_level: values.charge_level.value
        };
        
        postInvoiceChargeTypesPost({ body: submitValues }).then(async (resp) => {
            if (resp) {
                reset();
                setIsDialogOpen(false);
            }
        });
    };

    return (
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogContent className="max-w-4xl h-[90vh] flex flex-col overflow-hidden">
                <DialogHeader>
                    <DialogTitle className="text-3xl">Create New Pricing Category</DialogTitle>
                </DialogHeader>
                <DialogDescription className="text-rails-dark-blue flex-1 overflow-y-auto">
                    <div className="p-6">
                        <PricingCategoryForm formProps={formProps} chargeLevels={chargeLevels} />
                    </div>
                </DialogDescription>
                <div className="sticky bottom-0 right-0 p-6 bg-white border-t border-gray-200 w-full">
                    <div className="flex justify-end">
                        <Button onClick={handleSubmit(onSubmit)} type="submit">
                            {loading ? 'Creating' : 'Create'}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default PricingCategoryCreate;
