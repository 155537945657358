import React, { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Button } from '@/components/ui/button';
import InvoiceDetailView from './invoiceDetailView';
import InvoiceCreate from './invoiceCreate';
import { getInvoiceSummaryInvoiceInvoiceSummariesGet, getInvoiceCustomersGet } from '@/client/services.gen';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { evaluateFeatureFlag } from '@/lib/remoteConfig';
import LoadingSpinner from '@/components/ui/loading-spinner';

interface Invoice {
    customer_name: string;
    invoice_id: string;
    billing_period_start: string;
    billing_period_end: string;
    total_invoice_amount: number;
    status: string;
    voided_at: string;
}

interface Customer {
    label: string;
    value: string;
}

interface InvoiceStats {
    weeklyInvoiceCount: number;
    weeklyInvoiceCountChange: number;
    totalInvoicedAmount: number;
    totalInvoicedAmountChange: number;
    openInvoicesCount: number;
    openInvoicesTotalAmount: number;
}

const invoiceColumns = [
    {
        accessorKey: 'customer_name',
        header: 'Customer',
        cell: ({ row }: { row: any }) => (
            <span className="w-[200px] font-medium hover:underline">
                <Button variant="link" className="text-black">
                    {row.original.customer_name}
                </Button>
            </span>
        ),
        enableSorting: true,
    },
    {
        accessorKey: 'status',
        header: 'Status',
        cell: ({ row }: { row: any }) => <span className="max-w-[500px] text-wrap">{row.original.voided_at !== null ? "void" : row.original.status === "pending" ? <LoadingSpinner loading={true} /> : row.original.status}</span>,
        enableSorting: false,
    },
    {
        accessorKey: 'invoice_id',
        header: 'Invoice ID',
        cell: ({ row }: { row: any }) => <span className="max-w-[500px] text-wrap">{row.original.invoice_id}</span>,
        enableSorting: false,
    },
    {
        accessorKey: 'billing_period_start',
        header: 'Billing Period Start',
        cell: ({ row }: { row: any }) => <span className="max-w-[500px] text-wrap">{row.getValue('billing_period_start').substring(0, 10)}</span>,
        enableSorting: true,
    },
    {
        accessorKey: 'billing_period_end',
        header: 'Billing Period End',
        cell: ({ row }: { row: any }) => (
            <div className="flex flex-wrap space-x-2">
                <span className="max-w-[500px] text-wrap">{row.getValue('billing_period_end').substring(0, 10)}</span>
            </div>
        ),
        enableSorting: true,
    },
    {
        accessorKey: 'total_invoice_amount',
        header: 'Total Invoice Amount',
        cell: ({ row }: { row: any }) => (
            <div className="flex flex-wrap space-x-2">
                <span className="max-w-[500px] text-wrap">
                    <NumericFormat value={row.getValue('total_invoice_amount')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
                </span>
            </div>
        ),
        enableSorting: true,
    },
];

const InvoiceDetailViewComponent: React.FC<{
    invoiceOpen: boolean;
    setInvoiceOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedInvoice: Invoice | null;
}> = ({ invoiceOpen, setInvoiceOpen, selectedInvoice }) => <InvoiceDetailView open={invoiceOpen} setOpen={setInvoiceOpen} invoiceData={selectedInvoice} />;

interface InvoiceOverviewProps {
    filter?: any;
    dataTableOnly?: boolean;
}

const InvoiceOverview: React.FC<InvoiceOverviewProps> = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [invoiceTableData, setInvoiceTableData] = useState<Invoice[]>([]);
    const [customerData, setCustomerData] = useState<Customer[]>([]);
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
    const [invoiceOpen, setInvoiceOpen] = useState<boolean>(false);
    const [invoiceStats, setInvoiceStats] = useState<InvoiceStats>({
        weeklyInvoiceCount: 0,
        weeklyInvoiceCountChange: 0,
        totalInvoicedAmount: 0,
        totalInvoicedAmountChange: 0,
        openInvoicesCount: 0,
        openInvoicesTotalAmount: 0
    });
    const [createInvoiceFeatureFlagEnabled, setCreateInvoiceFeatureFlagEnabled] = useState<boolean>(false);

    useEffect(() => {
        const evalCreateInvoiceFeatureFlag = async () => {
            try {

                const ff = await evaluateFeatureFlag('adHocInvoiceCreate');
                setCreateInvoiceFeatureFlagEnabled(ff);
            } catch (error) {
                new Error('Error evaluating adHocInvoiceCreate feature flag');
            }
        };
        evalCreateInvoiceFeatureFlag();
    }, []);


    const fetchCustomers = async () => {
        const response = await getInvoiceCustomersGet();
        const filteredData = response
            .data!.filter((customer: any) => customer.active === true)
            .filter((customer: any) => customer.invoice_customer_id === null)
            .map((item: any) => ({
                label: item.name,
                value: item.id,
            }));
        setCustomerData(filteredData);
    };

    const fetchInvoices = async () => {
        const response = await getInvoiceSummaryInvoiceInvoiceSummariesGet();
        const sortedData = response.data!.sort((a: Invoice, b: Invoice) => {
            const dateA = new Date(a.billing_period_end);
            const dateB = new Date(b.billing_period_end);
            if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
                return dateB.getTime() - dateA.getTime();
            }
            return 0;
        });
        setInvoiceTableData(sortedData);

        // Calculate invoice stats
        const now = new Date();
        const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        const twoWeeksAgo = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000);

        const thisWeekInvoices = sortedData.filter(invoice => new Date(invoice.billing_period_end) >= oneWeekAgo);
        const lastWeekInvoices = sortedData.filter(invoice => new Date(invoice.billing_period_end) >= twoWeeksAgo && new Date(invoice.billing_period_end) < oneWeekAgo);

        const weeklyInvoiceCount = thisWeekInvoices.length;
        const lastWeekInvoiceCount = lastWeekInvoices.length;
        const weeklyInvoiceCountChange = ((weeklyInvoiceCount - lastWeekInvoiceCount) / lastWeekInvoiceCount) * 100;

        const totalInvoicedAmount = thisWeekInvoices.reduce((sum, invoice) => sum + parseFloat(invoice.total_invoice_amount.toString()), 0);
        const lastWeekTotalInvoicedAmount = lastWeekInvoices.reduce((sum, invoice) => sum + parseFloat(invoice.total_invoice_amount.toString()), 0);
        const totalInvoicedAmountChange = lastWeekTotalInvoicedAmount !== 0
            ? ((totalInvoicedAmount - lastWeekTotalInvoicedAmount) / lastWeekTotalInvoicedAmount) * 100
            : totalInvoicedAmount > 0 ? 100 : 0;

        const openInvoices = sortedData.filter(invoice => invoice.status === 'open');
        const openInvoicesCount = openInvoices.length;
        const openInvoicesTotalAmount = openInvoices.reduce((sum, invoice) => sum + parseFloat(invoice.total_invoice_amount.toString()), 0);

        setInvoiceStats({
            weeklyInvoiceCount,
            weeklyInvoiceCountChange: isNaN(weeklyInvoiceCountChange) ? 0 : weeklyInvoiceCountChange,
            totalInvoicedAmount,
            totalInvoicedAmountChange: isNaN(totalInvoicedAmountChange) ? 0 : totalInvoicedAmountChange,
            openInvoicesCount,
            openInvoicesTotalAmount
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([fetchInvoices(), fetchCustomers()]);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    const handleRowClick = (row: any) => {
        const selectedInvoiceItem = invoiceTableData.find((data) => data.invoice_id === row.original.invoice_id);
        setSelectedInvoice(selectedInvoiceItem || null);
        setInvoiceOpen(true);
    };

    if (isLoading) {
        return (
            <div className="h-screen flex items-center justify-center">
                <p className="text-xl">Loading...</p>
            </div>
        );
    }

    return (
        <div className="container mx-auto p-8">
            <div className="grid grid-cols-4 gap-4 mb-8">
                <Card>
                    <CardHeader>
                        <CardTitle>Invoices Generated</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <p className="text-3xl font-bold">{invoiceStats.weeklyInvoiceCount}</p>
                        <p className={`text-sm ${invoiceStats.weeklyInvoiceCountChange >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                            {invoiceStats.weeklyInvoiceCountChange >= 0 ? '↑' : '↓'} {Math.abs(invoiceStats.weeklyInvoiceCountChange).toFixed(2)}% from last week
                        </p>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader>
                        <CardTitle>Total Invoiced Amount</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <p className="text-3xl font-bold">
                            <NumericFormat value={invoiceStats.totalInvoicedAmount} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
                        </p>
                        <p className={`text-sm ${invoiceStats.totalInvoicedAmountChange >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                            {invoiceStats.totalInvoicedAmountChange >= 0 ? '↑' : '↓'} {Math.abs(invoiceStats.totalInvoicedAmountChange).toFixed(2)}% from last week
                        </p>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader>
                        <CardTitle>Open Invoices</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <p className="text-3xl font-bold">{invoiceStats.openInvoicesCount}</p>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader>
                        <CardTitle>Outstanding Amount</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <p className="text-3xl font-bold">
                            <NumericFormat value={invoiceStats.openInvoicesTotalAmount} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
                        </p>
                    </CardContent>
                </Card>
            </div>
            {invoiceTableData ?
                <DataTable
                    loading={isLoading}
                    data={invoiceTableData}
                    columns={invoiceColumns}
                    rowOnClick={handleRowClick}
                    // NOTE: Until we figure out how to toggle feature flags in
                    // the UI using config options, negate the boolean manually 
                    ActionButton={createInvoiceFeatureFlagEnabled ? <InvoiceCreate customerData={customerData} /> : null}
                    isMultiSelectRows={false}
                    showActionButtonInToolbar
                    isDownloadable={true}
                    downloadData={invoiceTableData.map((invoice) => ({
                        invoice_id: invoice.invoice_id,
                        customer_name: invoice.customer_name,
                        billing_period_start: invoice.billing_period_start,
                        billing_period_end: invoice.billing_period_end,
                        total_invoice_amount: invoice.total_invoice_amount,
                    }))}
                    tableName="invoices"
                /> : null
            }
            <InvoiceDetailViewComponent invoiceOpen={invoiceOpen} setInvoiceOpen={setInvoiceOpen} selectedInvoice={selectedInvoice} />
        </div>
    );
};

export default InvoiceOverview;
