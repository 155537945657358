import { useRef, useState } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { useAuth } from '../auth/AuthProvider.tsx';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import LoadingSpinner from '@/components/ui/loading-spinner';
import { useToast } from '@/components/common/use-toast';

interface ResetPasswordProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

interface AuthContextType {
    updatePassword: (oldPassword: string, newPassword: string) => Promise<void>;
    resetPassword: (email: string) => Promise<void>;
}

const ResetPassword = ({ open, setOpen }: ResetPasswordProps) => {
    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const passwordConfRef = useRef<HTMLInputElement>(null);
    const oldpasswordRef = useRef<HTMLInputElement>(null);
    const { updatePassword, resetPassword } = useAuth() as AuthContextType;
    const [notification, setNotification] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const { toast } = useToast();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setLoading(true);
        setNotification('');

        if (!passwordRef.current?.value || passwordRef.current.value.length < 8) {
            setLoading(false);
            return setNotification('Password must at least have eight characters');
        }

        if (passwordRef.current.value !== passwordConfRef.current?.value) {
            setLoading(false);
            return setNotification('Password does not match');
        }

        if (passwordRef.current.value && oldpasswordRef.current?.value) {
            updatePassword(oldpasswordRef.current.value, passwordRef.current.value)
                .then(() => {
                    setLoading(false);
                    setOpen(false);
                })
                .catch(() => {
                    setLoading(false);
                    setOpen(false);
                    toast({
                        description: (
                            <span className="flex text-lg">
                                <ExclamationTriangleIcon className="mt-0.5 mr-4 h-6 w-6 text-red-800 font-bold" /> We encountered an error. Please try again.
                            </span>
                        ),
                    });
                });
        }
        return true;
    };

    return (
        <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Reset password</AlertDialogTitle>
                    <AlertDialogDescription>
                        <form
                            onSubmit={handleSubmit}
                            className="mt-2 flex flex-col"
                        >
                            {notification && (
                                <span className="mb-4 w-80">
                                    <h2 className="text-rails-dark-blue font-poppinsReg text-sm">{notification}</h2>
                                </span>
                            )}
                            <input
                                ref={emailRef}
                                placeholder="Confirm email"
                                required
                                type="email"
                                className="mb-3 py-2 px-2 border focus:outline-none rounded-md font-poppinsReg"
                            />
                            <input
                                type="password"
                                ref={oldpasswordRef}
                                placeholder="Current password"
                                required
                                className="mb-3 py-2 px-2 border focus:outline-none rounded-md font-poppinsReg"
                            />
                            <input
                                type="password"
                                ref={passwordRef}
                                placeholder="New password"
                                required
                                className="mb-3 py-2 px-2 border focus:outline-none rounded-md  font-poppinsReg"
                            />
                            <input
                                type="password"
                                ref={passwordConfRef}
                                required
                                placeholder="Confirm new password"
                                className="mb-6 py-2 px-2 border focus:outline-none rounded-md  font-poppinsReg"
                            />
                        </form>
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={(e) => handleSubmit(e as unknown as React.FormEvent<HTMLFormElement>)} disabled={loading}>
                        {loading && (
                            <>
                                <LoadingSpinner loading={loading} /> <span className="pl-2">Resetting</span>
                            </>
                        )}
                        {!loading && <>Reset</>}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default ResetPassword;
