import React, { useState, useEffect } from 'react';
import { Switch } from '@/components/ui/switch';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import { Plus } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Form, FormField, FormItem, FormControl, FormMessage, FormLabel } from '@/components/ui/form';
import Select from 'react-select';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { getAllIntegrationWmsGet, postIntegrationWmsPost, putIntegrationWmsWmsIdPut } from '@/client/services.gen';

import { type GetAllIntegrationWmsGetResponse, type WMSConnection } from '@/client/types.gen';

const WmsIntegrations: React.FC = () => {
    const [availableWmsIntegrations, setAvailableWmsIntegrations] = useState<GetAllIntegrationWmsGetResponse>([]);
    const [selectedWms, setSelectedWms] = useState<WMSConnection | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const wmsSchema = z.object({
        name: z.string().min(1, 'Name is required'),
        connection_name: z.object({
            label: z.string(),
            value: z.string(),
        }),
        facility_id: z.string().optional(),
        username: z.string().min(1, 'Username is required'),
        password: z.string().min(1, 'Password is required'),
        api_key: z.string().optional(),
        refresh_token: z.string().optional(),
        is_active: z.boolean().optional(),
    });

    const form = useForm<z.infer<typeof wmsSchema>>({
        resolver: zodResolver(wmsSchema),
        defaultValues: {
            name: '',
            connection_name: { label: '', value: '' },
            facility_id: '',
            username: '',
            password: '',
            api_key: '',
            refresh_token: '',
            is_active: true,
        },
    });

    const { handleSubmit, reset, setValue } = form;

    const loadIntegrationsData = async () => {
        setLoading(true);
        const response = await getAllIntegrationWmsGet();
        setAvailableWmsIntegrations(response.data ?? []);
        setLoading(false);
    };

    const openWmsDialog = (wms?: WMSConnection) => {
        if (wms) {
            setSelectedWms(wms);
            setIsEditing(true);
            setValue('name', wms.name);
            setValue('connection_name', { label: wms.connection_name, value: wms.connection_name });
            setValue('facility_id', wms.facility_id || '');
            setValue('username', wms.username);
            setValue('password', wms.password);
            setValue('api_key', wms.api_key || '');
            setValue('refresh_token', wms.refresh_token || '');
            setValue('is_active', wms.is_active);
        } else {
            setSelectedWms(null);
            setIsEditing(false);
            reset();
        }
        setIsDialogOpen(true);
    };

    useEffect(() => {
        loadIntegrationsData();
    }, []);

    const columns = [
        {
            header: 'Name',
            accessorKey: 'name',
            cell: ({ row }: { row: { original: WMSConnection } }) => (
                <div className="w-[200px] font-medium hover:underline" onClick={() => openWmsDialog(row.original)}>
                    <a href="#">{row.original.name}</a>
                </div>
            ),
        },
        {
            header: 'Active',
            accessorKey: 'is_active',
            cell: ({ row }: { row: { original: WMSConnection } }) => <Switch disabled checked={row.original.is_active} />,
        },
    ];

    const wmsOptions = [
        { label: 'Amazon', value: 'Amazon' },
        { label: 'Shipstation', value: 'Shipstation' },
        { label: 'Infoplus', value: 'Infoplus' },
        { label: 'Shiphero', value: 'Shiphero' },
        { label: 'SKUVault', value: 'SKUVault' },
        { label: 'Packiyo', value: 'Packiyo' },
        { label: 'Extensiv', value: 'Extensiv' },
        { label: 'Deposco', value: 'Deposco' },
    ];

    const onSubmit = async (values: z.infer<typeof wmsSchema>) => {
        setLoading(true);
        setSuccessMessage(null);
        setError(null);
        try {
            if (isEditing && selectedWms) {
                await putIntegrationWmsWmsIdPut({
                    path: {
                        wmsId: selectedWms.id,
                    },
                    body: values,
                });
            } else {
                await postIntegrationWmsPost({
                    body: {
                        ...values,
                        connection_name: values.connection_name.value,
                    },
                });
            }
            await loadIntegrationsData();
            setIsDialogOpen(false);
            setSuccessMessage('WMS integration has been saved successfully.');
        } catch (error) {
            console.error('Error submitting WMS data:', error);
            setError('Failed to save WMS integration. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const AddWmsButton = (
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
                <Button onClick={() => openWmsDialog()} size="sm" className="ml-4 h-8 text-sm">
                    <Plus className="h-4 w-4 mr-2" />
                    WMS
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <h2 className="text-lg font-semibold mb-4">WMS Integration</h2>
                <Form {...form}>
                    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Reference Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="connection_name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>WMS</FormLabel>
                                    <FormControl>
                                        <Select options={wmsOptions} {...field} onChange={(val) => field.onChange(val)} value={field.value} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="username"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Username</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Username" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="password"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Password</FormLabel>
                                    <FormControl>
                                        <Input type="password" placeholder="Password" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="api_key"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>API Key / Client ID</FormLabel>
                                    <FormControl>
                                        <Input placeholder="API Key / Client ID" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="refresh_token"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Refresh Token / API Secret</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Refresh Token / API Secret" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="facility_id"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Facility ID</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Facility ID" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button type="submit" disabled={loading}>
                            {loading ? <div className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent"></div> : null}
                            {loading ? 'Submitting...' : isEditing ? 'Update' : 'Create'}
                        </Button>
                        {successMessage && <p className="text-green-500">{successMessage}</p>}
                        {error && <p className="text-red-500">{error}</p>}
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );

    return (
        <div className="container mx-auto p-8">
            {availableWmsIntegrations.length === 0 ? (
                <div className="flex flex-col items-center justify-center h-[calc(100vh-200px)]">
                    <h2 className="text-2xl font-semibold mb-4">Connect your first WMS</h2>
                    {AddWmsButton}
                </div>
            ) : (
                <DataTable columns={columns} data={availableWmsIntegrations} loading={loading} ActionButton={AddWmsButton} showActionButtonInToolbar={true} />
            )}
        </div>
    );
};

export default WmsIntegrations;
