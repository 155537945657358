/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';
import { Plus, MoreHorizontal } from 'lucide-react';
import { DataTable } from '@/components/ui/datatable/data-table';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import PricingRuleView from './pricingRuleView.tsx';
import { transformPricingCategories } from '../../../utils/invoice.tsx';
import PricingCategoryView from '../pricingCategories/pricingCategoryView.tsx';
import PricingRuleCreate from './pricingRuleCreate.tsx';
import PricingCategoryCreate from '../pricingCategories/pricingCategoryCreate.tsx';
import { 
    deleteInvoiceChargeTypesChargeTypeIdDelete,
    getRuleConditionOptionsInvoiceUiRuleConditionOptionsGet,
    getInvoiceCustomersGet,
    getInvoiceChargeTypesGet,
    getAllInvoiceRulesGet
} from '@/client/services.gen';

const PricingRules = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [selectedRule, setSelectedRule] = useState(null);
    const [pricingCategories, setPricingCategories] = useState([]);
    const [ruleConditionMetadata, setRuleConditionMetadata] = useState([]);
    const [open, setOpen] = useState(false);
    const [openCategory, setOpenCategory] = useState(false);
    const chargeLevels = [
        { label: 'Order Level', value: 'order_level' },
        { label: 'Shipment Level', value: 'shipment_level' },
        { label: 'Package Level', value: 'package_level' },
        { label: 'Product Level', value: 'product_level' },
        { label: 'Invoice Level', value: 'invoice_level' },
    ];

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [openPricingRuleForm, setOpenPricingRuleForm] = useState(false);
    const [openPricingCategoryForm, setOpenPricingCategoryForm] = useState(false);
    const openCategoryView = (row: { original: { id: string | number } }) => {
        const selectedCategoryArr = pricingCategories.filter((data: any) => data.id === row.original.id);
        setSelectedCategory(selectedCategoryArr[0]);
        setOpenCategory(true);
    };
    const openRuleView = (row: { original: { id: string | number } }) => {
        const selectedRuleArr = tableData.filter((data: any) => data.id === row.original.id);
        setSelectedRule(selectedRuleArr[0]);
        setOpen(true);
    };

    const fetchRules = () => {
        setIsLoading(true);

        Promise.all([
            getAllInvoiceRulesGet(), 
            getRuleConditionOptionsInvoiceUiRuleConditionOptionsGet(),
            getInvoiceCustomersGet(),
            getInvoiceChargeTypesGet()
        ]).then((res) => {
            let filteredRules = Array.isArray(res[0]?.data) ? res[0].data.filter((rule) => rule.charge_type !== 'package_rate' && !rule.name.includes('attributing missing')) : [];
            const customerList = Array.isArray(res[2]?.data) ? res[2].data : [];
            const customerMap = {};
            customerList.forEach(customer => {
                if (customer?.id && customer?.name) {
                    customerMap[customer.id] = customer.name;
                }
            });

            filteredRules = filteredRules.map(rule => ({
                ...rule,
                customer_ids: rule.customer_ids?.map(customerId => ({
                    label: customerMap[customerId] || customerId,
                    value: customerId
                })) || []
            }));

            let pricingCategoriesData = transformPricingCategories(res[3]?.data || [], filteredRules);
            pricingCategoriesData = pricingCategoriesData.filter(
                (category) => category.name !== 'Product' && category.name !== 'package_rate' && !category.name.includes('Special rule'),
            );

            console.log(res[1]?.data);

            setTableData(filteredRules);
            setPricingCategories(pricingCategoriesData);
            setRuleConditionMetadata(res[1]?.data || []);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        fetchRules();
    }, []);

    const pricingRuleColumns = [
        {
            accessorKey: 'name',
            header: 'Rule Name',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px] font-medium hover:underline" onClick={() => openRuleView(row)}>
                    <a href="#">{row.getValue('name')}</a>
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'customer_ids',
            header: 'Customers',
            cell: ({ row }: { row: any }) => {
                const customers = row.getValue('customer_ids');
                if (customers?.length > 0) {
                    const customerLabels = customers.map((customer) => customer.label);
                    const customerLabelsJoined = customerLabels.join(', ');
                    return <div className="customer-list">{customerLabelsJoined}</div>;
                }
                return 'All';
            },
            enableSorting: true,
            enableHiding: false,
        },

        {
            accessorKey: 'rule_conditions',
            header: 'Conditional Application',
            cell: ({ row }: { row: any }) => {
                const rules = row.getValue('rule_conditions');
                if (rules?.length > 0) {
                    return 'Yes';
                }
                return 'No';
            },
            enableSorting: true,
            enableHiding: false,
        },
    ];
    const pricingCategoryColumns = [
        {
            accessorKey: 'name',
            header: 'Charge Category',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px] font-medium hover:underline" onClick={() => openCategoryView(row)}>
                    <a href="#">{row.getValue('name')}</a>
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_on_invoice',
            header: 'Name on Invoice',
            cell: ({ row }: { row: any }) => <div className="w-[200px] font-medium">{row.getValue('charge_on_invoice')}</div>,
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_level',
            header: 'Charge Application Level',
            cell: ({ row }: { row: any }) => <div className="w-[200px] font-medium">{chargeLevels.find((charge) => charge.value === row.getValue('charge_level')).label}</div>,
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'rules',
            header: 'Number of Rules',
            cell: ({ row }: { row: any }) => {
                const rules = row.getValue('rules');
                if (rules?.length > 0) {
                    return <div className="customer-list">{rules.length}</div>;
                }
                return '';
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }: { row: any }) => {
                // Conditionally render the actions tab only if row.rules length is 0
                const rowRules = row.original?.rules || [];
                if (rowRules?.length === 0) {
                    return (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="h-8 w-8 p-0">
                                    <span className="sr-only">Open menu</span>
                                    <MoreHorizontal className="h-4 w-4" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuItem
                                    onClick={() => {
                                        deleteInvoiceChargeTypesChargeTypeIdDelete({ path: { chargeTypeId: row.original.id } }).then(() => {
                                            setOpenCategory(false);
                                        });
                                    }}
                                >
                                    Delete Charge Category
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    );
                }
                // Return null or an empty fragment if row.rules length is not 0
                return null;
            },
        },
    ];

    const TableActionButton = () => (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button className="p-4">
                    <Plus className="h-4 w-4 mr-2" />
                    New Charge
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <DropdownMenuItem
                    onClick={() => {
                        setOpenPricingCategoryForm(true);
                    }}
                >
                    Charge Category
                </DropdownMenuItem>
                <DropdownMenuItem
                    onClick={() => {
                        setOpenPricingRuleForm(true);
                    }}
                >
                    Pricing Rule
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );

    const DataTableComponent = () => (
        <DataTable
            loading={isLoading}
            data={pricingCategories}
            columns={pricingCategoryColumns}
            isMultiSelectRows={false}
            ActionButton={<TableActionButton />}
            collapsible
            showActionButtonInToolbar
            showActionButtonInCollapsible={false}
            showToolBar
            collapsibleContent={({ row }) => {
                if (row?.rules) {
                    return (
                        <DataTable
                            loading={isLoading}
                            data={row.rules}
                            columns={pricingRuleColumns}
                            isMultiSelectRows={false}
                            showPagination={false}
                            showToolBar={false}
                            showActionButtonInToolbar={false}
                            showActionButtonInCollapsible
                            collapsible={false}
                        />
                    );
                }
                return null;
            }}
        />
    );

    const PricingRuleViewComponent = () => (
        <PricingRuleView open={open} setOpen={setOpen} pricingRule={selectedRule} setPricingRule={setSelectedRule} ruleConditionMetadata={ruleConditionMetadata} />
    );

    const PricingCategoryViewComponent = () => {
        if (openCategory) {
            return (
                <PricingCategoryView
                    openCategory={openCategory}
                    setOpenCategory={setOpenCategory}
                    pricingCategory={selectedCategory}
                    setPricingCategory={setSelectedCategory}
                    chargeLevels={chargeLevels}
                />
            );
        }
        return null;
    };

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Client Charges</h1>
                            <h2 className="text-xs text-rails-dark-blue">Create charges and manage rules used to calculate invoice line items.</h2>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="container mx-auto py-20">{tableData && <DataTableComponent />}</div>
                        </div>
                    </div>
                </main>
            </div>

            {ruleConditionMetadata && <PricingRuleViewComponent />}
            <PricingCategoryViewComponent />
            {ruleConditionMetadata && <PricingRuleCreate isDialogOpen={openPricingRuleForm} setIsDialogOpen={setOpenPricingRuleForm} ruleConditions={ruleConditionMetadata} />}
            <PricingCategoryCreate chargeLevels={chargeLevels} isDialogOpen={openPricingCategoryForm} setIsDialogOpen={setOpenPricingCategoryForm} />
        </div>
    );
};

export default PricingRules;
