import * as z from 'zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../ui/dialog';
import PricingRuleForm from './pricingRuleForm';
import LoadingSpinner from '../../common/loadingSpinner';
import { postInvoiceRulesPost } from '@/client/services.gen';
import type { AllowedValues, backend__db__schemas__invoice__RuleCreate, Filter, RuleConditionBase } from '@/client/types.gen';

interface PricingRuleCreateProps {
    isDialogOpen: boolean;
    setIsDialogOpen: (open: boolean) => void;
    ruleConditions: Array<{
        col: string;
        name: string;
        allowed_filters: Array<any>;
        allowed_values: Record<string, any> | null;
    }>;
}

const PricingRuleCreate = ({ isDialogOpen, setIsDialogOpen, ruleConditions }: PricingRuleCreateProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const pricingRuleCreateFormDefaultValues = {
        name: '',
        default: false,
        rule_conditions: [],
        charge_type: [],
        tiers: [],
        customer_ids: [],
        group_ids: [],
        tiers_are_cumulative: false,
    };

    const dropDownSchema = z.object({
        label: z.string(),
        value: z.string(),
    });

    const pricingRuleConditionsSchema = z.object({
        col: dropDownSchema,
        filter: dropDownSchema,
        value: z.string().min(1, { message: 'Required' }).optional().or(dropDownSchema.optional()).or(z.array(dropDownSchema)),
    });

    const pricingTierRangeConditionSchema = z.object({
        rangeCol: z.string().nullable().optional(),
        rangeStart: z.string().nullable().optional(),
        rangeEnd: z.string().nullable().optional(),
    });

    const pricingChoice = z.object({
        method: z.string().trim().min(1, { message: 'Method is required.' }),
        value: z.string().min(1, { message: 'Value is required.' }),
        col: z.string().nullable().optional(),
        filter: z.string().nullable().optional(),
    });

    const pricingTierSchema = z
        .object({
            tier_conditions: pricingTierRangeConditionSchema.optional(),
            pricing_choices: z.array(pricingChoice),
            pricing_choices_filter: z.string().nullable().optional(),
        })
        .refine(
            (input) => {
                const hasSpecialMethod = input.pricing_choices.some((choice) => choice.method === 'variable_cost' || choice.method === 'cumulative');
                if (hasSpecialMethod) {
                    if (!input.tier_conditions) {
                        return false;
                    }
                    const { rangeCol, rangeStart } = input.tier_conditions;
                    if (!rangeCol || !rangeStart) {
                        return false;
                    }
                }
                return true;
            },
            {
                message: 'Defining a tier is necessary when creating per unit pricing. Ensure a range start is provided at the minimum.',
                path: ['tier_conditions'],
            },
        )
        .refine(
            (input) => {
                // Require pricing_choices_filter if there is more than one pricing_choice
                if (input.pricing_choices.length > 1 && !input.pricing_choices_filter) {
                    return false;
                }
                return true;
            },
            {
                message: 'Please enter a selection method is selected if more than one pricing type is entered',
                path: ['tier_conditions'],
            },
        );

    const pricingRuleSchema = z
        .object({
            name: z.string().trim().min(3, { message: 'Required' }),
            default: z.boolean(),
            rule_conditions: z.array(pricingRuleConditionsSchema).optional(),
            charge_type: dropDownSchema,
            tiers: z.array(pricingTierSchema),
            customer_ids: z.array(dropDownSchema).optional(),
            group_ids: z.array(dropDownSchema).optional(),
            tiers_are_cumulative: z.boolean(),
        })
        .refine(
            (input) => {
                if (!input.default && (!input.customer_ids || input.customer_ids.length === 0)) {
                    return false;
                }
                return true;
            },
            {
                message: 'Customer selection is required if this rule is not a default rule.',
                path: ['customer_ids'],
            },
        );

    const formProps = useForm({
        resolver: zodResolver(pricingRuleSchema),
        defaultValues: pricingRuleCreateFormDefaultValues,
    });

    const { handleSubmit, reset } = formProps;

    const onSubmit = async (values: any) => {
        setIsLoading(true);
        try {
            const transformedValues: backend__db__schemas__invoice__RuleCreate = {
                name: values.name,
                default: values.default,
                charge_type_id: values.charge_type.value,
                customer_ids: values.customer_ids?.map((customer: any) => customer.value) || [],
                tiers_are_cumulative: values.tiers_are_cumulative,
                rule_conditions: values.rule_conditions?.map((condition: any): RuleConditionBase => ({
                    col: condition.col.value as AllowedValues,
                    filter: condition.filter.value as Filter,
                    value: Array.isArray(condition.value)
                        ? condition.value.map((v: any) => v.value).join(',')
                        : condition.value?.value || condition.value
                })) || [],
                tiers: values.tiers.map((tier: any) => ({
                    pricing_choices: tier.pricing_choices.map((choice: any) => ({
                        method: choice.method,
                        value: choice.value,
                        col: choice.col || null
                    })),
                    pricing_choices_filter: tier.pricing_choices_filter,
                    tier_conditions: tier.tier_conditions?.rangeCol 
                        ? [
                            {
                                col: tier.tier_conditions.rangeCol,
                                filter: '>=' as Filter,
                                value: tier.tier_conditions.rangeStart
                            },
                            ...(tier.tier_conditions.rangeEnd ? [{
                                col: tier.tier_conditions.rangeCol,
                                filter: '<=' as Filter,
                                value: tier.tier_conditions.rangeEnd
                            }] : [])
                        ]
                        : []
                }))
            };

            const resp = await postInvoiceRulesPost({
                body: transformedValues
            });
            if (resp) {
                setIsDialogOpen(false);
                reset();
            }
        } catch (error) {
            console.error('Error creating rule:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogContent className="max-w-4xl h-[90vh] flex flex-col">
                <DialogHeader>
                    <DialogTitle className="text-3xl">Create New Pricing Rule</DialogTitle>
                </DialogHeader>
                <DialogDescription className="text-rails-dark-blue flex-1 overflow-y-auto">
                    {ruleConditions && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <PricingRuleForm 
                                formProps={formProps} 
                                ruleConditions={ruleConditions}
                                onSubmit={onSubmit}
                                isLoading={isLoading}
                            />
                            <div className="fixed bottom-0 right-0 p-6 bg-white border-t border-gray-200 w-full max-w-4xl">
                                <div className="flex justify-end">
                                    <Button disabled={isLoading} type="submit">
                                        {isLoading && (
                                            <>
                                                <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                                                Creating
                                            </>
                                        )}
                                        {!isLoading && <>Create</>}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}
                </DialogDescription>
            </DialogContent>
        </Dialog>
    );
};

export default PricingRuleCreate;
