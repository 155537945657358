import * as z from 'zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import PricingCategoryForm from './forms/pricingCategoryForm';
import { editInvoiceChargeTypesChargeTypeIdEditPost } from '@/client/services.gen';

const PricingCategoryEdit = ({ pricingCategoryValues, setPricingCategory, chargeLevels }) => {
    const [loading, setLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);    

    const pricingCategoryCreateFormDefaultValues = {
        name: pricingCategoryValues.name,
        charge_on_invoice: pricingCategoryValues.charge_on_invoice,
        description: pricingCategoryValues.description,
        required: pricingCategoryValues.required,
        charge_level: chargeLevels.filter((charge) => charge.value === pricingCategoryValues.charge_level)[0],
    };

    const dropDownSchema = z.object({
        label: z.string(),
        value: z.string(),
    });

    const pricingCategorySchema = z.object({
        name: z.string().trim().min(3, { message: 'Required' }),
        charge_on_invoice: z.string().trim().min(3, { message: 'Required' }),
        default: z.boolean().optional(),
        required: z.boolean(),
        description: z.string().trim().min(3, { message: 'Required' }),
        charge_level: dropDownSchema,
    });

    const formProps = useForm({
        resolver: zodResolver(pricingCategorySchema),
        defaultValues: pricingCategoryCreateFormDefaultValues,
    });

    const { handleSubmit, reset } = formProps;

    const onSubmit = (values) => {
        setLoading(true);
        const transformedValues = {
            ...values,
            charge_level: values.charge_level.value
        };
        editInvoiceChargeTypesChargeTypeIdEditPost({ 
            path: { chargeTypeId: pricingCategoryValues.id }, 
            body: transformedValues 
        }).then(async (resp) => {
            if (resp) {
                setIsDialogOpen(false);
            }
        });
    };

    return (
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
                <Button
                    onClick={() => {
                        setIsDialogOpen(true);
                    }}
                    className="ml-4"
                >
                    Edit
                </Button>
            </DialogTrigger>
            {isDialogOpen && (
                <DialogContent className="max-w-2xl max-h-[90vh] overflow-y-auto">
                    <DialogHeader>
                        <DialogTitle className="text-3xl">Edit Pricing Category</DialogTitle>
                        <DialogDescription className="text-rails-dark-blue">
                            <PricingCategoryForm formProps={formProps} chargeLevels={chargeLevels} />
                            <div className="sticky bottom-0 bg-white py-4 mt-8">
                                <Button 
                                    onClick={handleSubmit((e) => onSubmit(e))} 
                                    type="submit"
                                    className={loading ? 'animate-pulse disabled' : ''}
                                >
                                    {loading ? 'Saving' : 'Save'}
                                </Button>
                            </div>
                        </DialogDescription>
                    </DialogHeader>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default PricingCategoryEdit;
