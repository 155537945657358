import { useState, useEffect } from 'react';
import { DataTable } from '@/components/ui/datatable/data-table';
import { getAllStorageLocationsGet } from '@/client/services.gen';
import type { Location } from '@/client/types.gen';

const Locations = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState<Location[]>([]);

    const fetchLocations = async () => {
        setIsLoading(true);
        const { data, error } = await getAllStorageLocationsGet();
        if (error) {
            console.error('Failed to fetch locations:', error);
        } else {
            setTableData(data || []);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchLocations();
    }, []);

    const columns = [
        {
            accessorKey: 'name',
            header: 'Name',
            cell: ({ row }: { row: any }) => <div className="w-[100px] font-medium">{row.original.name}</div>,
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'warehouse',
            header: 'Warehouse',
            cell: ({ row }: { row: any }) => <div className="w-[100px] font-medium">{row.original.warehouse?.name}</div>,
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'type',
            header: 'Type',
            cell: ({ row }: { row: any }) => <div className="w-[100px] font-medium">{row.original.storage_type?.name}</div>,
            enableSorting: true,
            enableHiding: false,
        },
    ];

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Locations</h1>
                            <h2 className="text-xs text-rails-dark-blue">Manage locations.</h2>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="container mx-auto py-20">
                                {tableData && <DataTable loading={isLoading} data={tableData} columns={columns} isMultiSelectRows={false} tableName="locations" />}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Locations;
