/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';
import { DataTable } from '@/components/ui/datatable/data-table.tsx';
import { readManyV2WmsInvoiceCustomersGet, readManyV2WmsCustomersGet, createV2WmsInvoiceCustomersPost, updateV2WmsInvoiceCustomersIdPut, setInvoiceCustomerIdV2WmsCustomersIdInvoiceCustomerPost } from '@/client/services.gen';
import { Switch } from '@/components/ui/switch';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import type { InvoiceCustomer } from '@/client/types.gen';
import { Form, FormField, FormItem, FormControl, FormMessage, FormLabel } from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { PencilIcon } from '@heroicons/react/24/outline';
import Select from 'react-select';

const customerSchema = z.object({
    name: z.string().min(1, 'Name is required'),
    active: z.boolean(),
    wmsCustomerIds: z.array(z.string()).nullable(),
});

const CustomersCreate = ({ 
    createDialogOpen, 
    setCreateDialogOpen, 
    formData, 
    handleFormChange, 
    handleCreateSubmit 
}) => (
    <Dialog open={createDialogOpen} onOpenChange={setCreateDialogOpen}>
        <DialogTrigger asChild>
            <Button variant="default">Create Customer</Button>
        </DialogTrigger>
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Create New Customer</DialogTitle>
            </DialogHeader>
            <form onSubmit={handleCreateSubmit} className="space-y-4">
                <div className="space-y-2">
                    <Label htmlFor="name">Name</Label>
                    <Input
                        id="name"
                        name="name"
                        defaultValue={formData.name}
                        onChange={handleFormChange}
                        required
                    />
                </div>
                <Button type="submit">Create</Button>
            </form>
        </DialogContent>
    </Dialog>
);

const Customers = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [wmsCustomers, setWmsCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [formData, setFormData] = useState<InvoiceCustomer>({
        company_id: '',
        name: '',
        active: true
    });
    const [editingRow, setEditingRow] = useState(null);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

    const fetchInvoiceCustomers = async () => {
        setIsLoading(true);
        const { data: invoiceCustomers, error } = await readManyV2WmsInvoiceCustomersGet();
        const { data: wmsCustomers } = await readManyV2WmsCustomersGet();
        
        if (error) {
            console.error(error);
        }

        const enrichedData = invoiceCustomers.map(invoiceCustomer => {
            const associatedWmsCustomers = wmsCustomers.filter(
                wmsCustomer => wmsCustomer.invoice_customer_id === invoiceCustomer.id
            );

            const uniqueWmsCustomers = Array.from(new Set(associatedWmsCustomers.map(c => c.id)))
                .map(id => associatedWmsCustomers.find(c => c.id === id))
                .filter(Boolean);

            return {
                ...invoiceCustomer,
                wms_customer_names: uniqueWmsCustomers.length 
                    ? uniqueWmsCustomers.map(c => c.name).join(', ')
                    : '-',
                wms_customer_ids: uniqueWmsCustomers.map(c => c.id)
            };
        });

        setTableData(enrichedData);
        setIsLoading(false);
    };

    const fetchWmsCustomers = async () => {
        const { data, error } = await readManyV2WmsCustomersGet();
        if (error) {
            console.error(error);
        }
        setWmsCustomers(data);
    };

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                fetchInvoiceCustomers(),
                fetchWmsCustomers()
            ]);
        };
        fetchData();
    }, []);

    const handleCreateSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await createV2WmsInvoiceCustomersPost({
                body: formData
            });
            setCreateDialogOpen(false);
            fetchInvoiceCustomers();
            // Reset form
            setFormData({
                company_id: '',
                name: '',
                email: null,
                active: true
            });
        } catch (error) {
            console.error('Error creating customer:', error);
        }
    };

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const CustomerForm = () => {
        const [isSubmitting, setIsSubmitting] = useState(false);
        const form = useForm<z.infer<typeof customerSchema>>({
            resolver: zodResolver(customerSchema),
            defaultValues: {
                name: selectedCustomer?.name ?? '',
                active: selectedCustomer?.active ?? true,
                wmsCustomerIds: selectedCustomer?.wms_customer_ids ?? [],
            },
        });

        const onSubmit = async (values: z.infer<typeof customerSchema>) => {
            try {
                setIsSubmitting(true);
                
                await updateV2WmsInvoiceCustomersIdPut({
                    path: { id: selectedCustomer.id },
                    body: { ...values }
                });

                // Update all WMS customer associations
                if (values.wmsCustomerIds && values.wmsCustomerIds.length > 0) {
                    await Promise.all(
                        values.wmsCustomerIds.map(wmsCustomerId =>
                            setInvoiceCustomerIdV2WmsCustomersIdInvoiceCustomerPost({
                                path: { id: wmsCustomerId },
                                query: { invoice_customer_id: selectedCustomer.id }
                            })
                        )
                    );
                }

                await fetchInvoiceCustomers();
                setIsEditDialogOpen(false);
            } catch (error) {
                console.error('Error updating customer:', error);
            } finally {
                setIsSubmitting(false);
            }
        };

        const getWmsCustomerOptions = () => {
            return wmsCustomers.map((customer) => ({
                value: customer.id,
                label: customer.name
            }));
        };

        return (
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                    <FormField
                        control={form.control}
                        name="name"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Name</FormLabel>
                                <FormControl>
                                    <Input {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="active"
                        render={({ field }) => (
                            <FormItem>
                                <div className="flex items-center space-x-2"><FormLabel>Active</FormLabel>
                                <FormControl>
                                    <Switch
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                    />
                                </FormControl>
                                </div>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="wmsCustomerIds"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>WMS Customers</FormLabel>
                                <FormControl>
                                    <Select
                                        isMulti
                                        value={getWmsCustomerOptions().filter(
                                            option => field.value?.includes(option.value)
                                        )}
                                        onChange={(options) => 
                                            field.onChange(options ? options.map(opt => opt.value) : [])
                                        }
                                        options={getWmsCustomerOptions()}
                                        className="w-full"
                                        classNamePrefix="select"
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? "Updating..." : "Update Customer"}
                    </Button>
                </form>
            </Form>
        );
    };

    const handleEditCustomer = (customer) => {
        setSelectedCustomer(customer);
        setIsEditDialogOpen(true);
    };

    const columns = [
        {
            accessorKey: 'name',
            header: "Name",
            cell: ({ row }) => (
                <div className="flex items-center space-x-2">
                    <span className="w-[200px]">{row.getValue('name')}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'active',
            header: "Active",
            cell: ({ row }) => (
                <div className="w-[200px]">
                    <Switch 
                        checked={row.getValue('active')} 
                        disabled
                    />
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'wms_customer_names',
            header: "WMS Customers",
            cell: ({ row }) => (
                <div className="w-[200px]">
                    {row.getValue('wms_customer_names')}
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            id: 'actions',
            cell: ({ row }) => (
                <Button 
                    variant="ghost" 
                    size="sm" 
                    onClick={() => handleEditCustomer(row.original)}
                >
                    <PencilIcon className="h-4 w-4" />
                </Button>
            ),
        }
    ];

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Customers</h1>
                            <h2 className="text-xs text-rails-dark-blue">Manage customers for invoicing.</h2>
                            <div className="container mx-auto py-8">
                                {tableData && (
                                    <DataTable
                                        loading={isLoading}
                                        data={tableData}
                                        columns={columns}
                                        ActionButton={
                                            <CustomersCreate 
                                                createDialogOpen={createDialogOpen}
                                                setCreateDialogOpen={setCreateDialogOpen}
                                                formData={formData}
                                                handleFormChange={handleFormChange}
                                                handleCreateSubmit={handleCreateSubmit}
                                            />
                                        }
                                        isMultiSelectRows={false}
                                        isDownloadable
                                        downloadData={tableData}
                                        tableName="customers"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Edit Customer</DialogTitle>
                    </DialogHeader>
                    <CustomerForm />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Customers;
